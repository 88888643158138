import { useHistory } from "react-router-dom";
import {
  IonButtons, IonContent, IonHeader, IonPage, IonToolbar,
  IonIcon, IonInput, IonItem, IonAvatar, IonImg, IonLoading,
  IonList, IonLabel, useIonLoading, useIonViewWillEnter, IonToast, IonDatetime
} from '@ionic/react';
import Cookies from "universal-cookie";
// import { GoogleMap } from "@capacitor/google-maps";
// import { Geolocation } from "@capacitor/geolocation";

import {
  Camera, CameraResultType, CameraSource,
} from "@capacitor/camera";

import { App } from '@capacitor/app';
import { arrowBackOutline, createOutline, cameraOutline, homeOutline } from "ionicons/icons";
import Swal from 'sweetalert2'
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import { base_url, user_token, sweetAlart, userData } from "../App";
import axios from "axios";
import "./MyAccount.scss";
import PullToRefresh from "../components/PullToRefresh";


interface coords {
  latitude: number;
  longitude: number;
}

const MyAccount: React.FC = () => {

  const Swal = require('sweetalert2')
  const key = (JSON.parse(localStorage.getItem('user_data') as string)) ? JSON.parse(localStorage.getItem('user_data') as string).user.role.key : null;
  var [image, setImage] = useState<any>("");
  const [selectedImage, setSelectedImage] = useState<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [shiftStartTime, setShiftStartTime] = useState('');
  const [shiftEndTime, setShiftEndTime] = useState('');
  // const [coordinates, setCoordinates] = useState<any>("");
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const history = useHistory();
  const [loading] = useIonLoading();
  const [coords, setCoords] = useState<coords>({
    latitude: 0,
    longitude: 0,
  });
  interface userDetail {
    image_url: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    adress: string,
    email: string,
    id: string,
    shift_starts_at: string,
    shift_ends_at: string
    latitude: number,
    longitude: number,
  }

  // update-profile-image call

  // useIonViewWillEnter(() => {
  //   setShowLoading(true);
  //   if (image !== "") {
  //     axios
  //       .post(
  //         base_url + "/api/attendance/update-profile-image",
  //         {
  //           image: image,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization:
  //               "Bearer " +
  //               JSON.parse(localStorage.getItem("user_data") as string).token
  //                 .token,
  //           },
  //         }
  //       )
  //       .then(function (response) {

  //         console.log(response);
  //         if (response.data.status == true) {
  //           var user_data = JSON.parse(
  //             localStorage.getItem("user_data") as string
  //           );
  //           user_data.user.image = response.data.user.image;
  //           user_data.user.image_url = response.data.user.image_url;
  //           localStorage.setItem('user_data', JSON.stringify(user_data));
  //           history.push("home");
  //           setShowLoading(false);
  //         } else {
  //           alert(response.data.message);
  //           history.push("/my-account");
  //           setShowLoading(false);
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }
  // }, [image]);
  function updateProfileImage(image: any, history: any, setShowLoading: any) {
    setShowLoading(true);

    if (image !== "") {
      axios
        .post(
          base_url + "/api/attendance/update-profile-image",
          {
            image: image,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("user_data") as string).token
                  .token,
            },
          }
        )
        .then(function (response) {
          console.log(response);
          if (response.data.status == true) {
            var user_data = JSON.parse(localStorage.getItem("user_data") as string);
            user_data.user.image = response.data.user.image;
            user_data.user.image_url = response.data.user.image_url;
            localStorage.setItem('user_data', JSON.stringify(user_data));
            history.push("home");
            setShowLoading(false);
          } else {
            alert(response.data.message);
            history.push("/my-account");
            setShowLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useIonViewWillEnter(() => {
    updateProfileImage(image, history, setShowLoading);
  }, [image]);


  useIonViewWillEnter(() => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + "/api/account-setting-get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setSelectedImage(res.data.data.image_url);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const profile_change = () => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + "/api/account-setting-get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setSelectedImage(res.data.data.image_url);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useIonViewWillEnter(() => {
    console.log(App.getInfo());
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + `/api/users/${idd}/show`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(async (res) => {
        let user = res.data.data;
        await setCoords({
          latitude: parseFloat(user.latitude),
          longitude: parseFloat(user.longitude)
        })

        await setuserDetail({
          first_name: user.first_name,
          phone_no: user.phone_no,
          last_name: user.last_name,
          email: user.email,
          latitude: coords.latitude,
          longitude: coords.longitude,
          image: user.image,
          image_url: user.image_url,
        });


        setShiftStartTime(user.shift_starts_at);
        setShiftEndTime(user.shift_ends_at);
        setShowLoading(false);
        // createMap(Number(user.latitude), Number(user.longitude));
      })
      .catch((err) => {
        console.log(err);
      });
  });

  // Pull to refresh code start

  const handleData = (userData: any) => {
    if (!userData) {
      MyAccount();
    }
  }
  const MyAccount = () => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + `/api/users/${idd}/show`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(async (res) => {
        let user = res.data.data;
        await setCoords({
          latitude: parseFloat(user.latitude),
          longitude: parseFloat(user.longitude)
        })

        await setuserDetail({
          first_name: user.first_name,
          phone_no: user.phone_no,
          last_name: user.last_name,
          email: user.email,
          latitude: coords.latitude,
          longitude: coords.longitude,
          image: user.image,
          image_url: user.image_url,
        });


        setShiftStartTime(user.shift_starts_at);
        setShiftEndTime(user.shift_ends_at);
        setShowLoading(false);
        // createMap(Number(user.latitude), Number(user.longitude));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useIonViewWillEnter(() => {
    MyAccount();
  }, []);

  // Pull to refresh code end

  const apiKey = "AIzaSyBCafTKnC7aHB6eB32KXrmtoFGPJ1mq9Go";
  useEffect(() => {
    // getCurrentPosition()
    console.log(coords);
    console.log(navigator.onLine)
    // createMap();
  }, [coords]);

  // let center = {
  //   lat: coords.latitude,
  //   lng: coords.longitude,
  // };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: coords.latitude,
      lng: coords.longitude,
    });
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const [userDetail, setuserDetail] = useState<any>({
  });

  const cookies = new Cookies();
  var idd = cookies.get('id');
  useEffect(() => {
  }, []);

  const updateClick = (e: any) => {
    e.preventDefault();
    setShowLoading(true);
    profile_change();
    updateProfileImage(image, history, setShowLoading);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user_token.token
    }
    axios.post(base_url + '/api/auth/account-setting-update',
      {
        first_name: userDetail.first_name,
        last_name: userDetail.last_name,
        phone_no: userDetail.phone_no,
        email: userDetail.email,
        shift_starts_at: shiftStartTime,
        shift_ends_at: shiftEndTime,
        user_id: idd,
        longitude: userDetail.longitude,
        latitude: userDetail.latitude,
        image_url: image ? image : userDetail.image_url
      },
      {
        headers: headers,
      })
      .then(function (response) {
        // if (response.data.status == true) {
        //   setAlertMessage(response.data.message);
        //   setShowToast2(true);
        //   setShowLoading(false);
        // }
        // else {
        //   setAlertMessage(response.data.message);
        //   setShowToast2(true);
        //   setShowLoading(false);
        // }
        if (response.data.status == true) {
          sweetAlart(response.data.message, 'success');
          setShowLoading(false);
          history.replace("/");
        } else {
          sweetAlart(response.data.message, 'warning');
          setShowLoading(false);
          // setAlertMessage(response.data.message);
          // setShowToast1(true);
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  };



  const editClick = (id: any) => {
    history.push("/account_update", id);
    const cookies = new Cookies();
    cookies.set('id', id, { path: '/' });
  }
  const backButtonClick = () => {
    history.push("/Home");
  }
  const handleClick = () => {
    history.push("/Home");
  };

  const takePicture = async () => {
    const cameraResult = await Camera.getPhoto({
      quality: 20,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos,
    })
      .then(async (cameraResult) => {
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        let base64Image = "data:image/jpeg;base64," + cameraResult.base64String;
        setImage(base64Image);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <IonPage>

      <IonContent fullscreen className="all_input">
        <PullToRefresh userDataProps={handleData} />
        <IonHeader>
          <IonToolbar className="my_account">
            <div className="profile_header">
              <div className="back_btn">
                <IonButtons slot="start">
                  <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                  <h4>My Account</h4>
                </IonButtons>
              </div>
              <div className="home_icon">
                <IonIcon onClick={handleClick} icon={homeOutline} />
              </div>
            </div>
            <div className="person_detail">
              <div className="profile_detail">
                <IonAvatar>
                  <IonImg
                    src={
                      image
                        ? image
                        : selectedImage
                          ? selectedImage
                          : require("../assets/images/user.png").default
                    }
                  ></IonImg>

                  <IonIcon
                    className="camera_icon"
                    icon={cameraOutline}
                    onClick={() => takePicture()}
                  />
                </IonAvatar>
                <IonLoading
                  cssClass="my-custom-class"
                  isOpen={showLoading}
                  onDidDismiss={() => setShowLoading(false)}
                  message={"Please wait..."}
                />
                <div className="contact_detail">
                  <strong>{userDetail.first_name + " " + userDetail.last_name}</strong>
                  <h5>{userDetail.email}</h5>
                </div>
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
        />
        <IonList>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">First Name</IonLabel>
            <IonInput
              value={userDetail.first_name}
              placeholder={"First Name"} type="text"
              onIonChange={(e) => {
                let user_data = userDetail;
                user_data.first_name = e.detail.value;
                setuserDetail(user_data);
              }}></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">Last Name</IonLabel>
            <IonInput value={userDetail.last_name}
              placeholder={"Last Name"} type="text"
              onIonChange={(e) => {
                let user_data = userDetail;
                user_data.last_name = e.detail.value;
                setuserDetail(user_data);
              }}></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">Email Address</IonLabel>
            <IonInput value={userDetail.email} placeholder={"hi@ionicframework.com"} type="email"
              onIonChange={(e) => {
                let user_data = userDetail;
                user_data.email = e.detail.value;
                setuserDetail(user_data);
              }}></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">Contact No</IonLabel>
            <IonInput value={userDetail.phone_no} placeholder={"+92 ***-*******"} type="number"
              onIonChange={(e) => {
                userDetail.phone_no = e.detail.value;
                setuserDetail(userDetail);
              }}></IonInput>
          </IonItem>
          {key && key === 'user' ?
            <div>
              <IonItem className="ions_input">
                <IonLabel position="stacked" className="label">Shift starting</IonLabel>
                <IonDatetime
                  readonly
                  placeholder="Shift starting"
                  displayFormat="h:mm:a"
                  value={shiftStartTime}
                ></IonDatetime>
              </IonItem>
              <IonItem className="ions_input">
                <IonLabel position="stacked" className="label">Shift ending</IonLabel>
                <IonDatetime
                  readonly
                  placeholder="Shift ending"
                  displayFormat="h:mm:a"
                  value={shiftEndTime}
                ></IonDatetime>
              </IonItem>
            </div> : ""}
          {key && key === 'company' ?
            <div>
              <IonItem className="ions_input">
                <IonLabel position="stacked" className="label">Shift starting</IonLabel>
                <IonDatetime
                  placeholder="Shift starting"
                  displayFormat="h:mm:a"
                  value={shiftStartTime}
                  onIonChange={
                    (e) => {
                      setShiftStartTime(e.detail.value!);
                    }
                  }
                ></IonDatetime>
              </IonItem>
              <IonItem className="ions_input">
                <IonLabel position="stacked" className="label">Shift ending</IonLabel>
                <IonDatetime
                  placeholder="Shift ending"
                  displayFormat="h:mm:a"
                  value={shiftEndTime}
                  onIonChange={
                    (e) => {
                      setShiftEndTime(e.detail.value!);
                    }
                  }
                ></IonDatetime>
              </IonItem>
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: "400px",
                  }}
                  center={{ lat: coords.latitude, lng: coords.longitude }}
                  zoom={5}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  onClick={(e) => {
                    setCoords({
                      latitude: e.latLng?.lat() ? e.latLng?.lat() : coords.latitude,
                      longitude: e.latLng?.lng() ? e.latLng?.lng() : coords.longitude
                    })
                  }}

                >
                  {/* Child components, such as markers, info windows, etc. */}
                  <Marker
                    visible
                    position={{ lat: coords.latitude, lng: coords.longitude }}
                    draggable
                    onDrag={(e) => {
                      setCoords({
                        latitude: e.latLng?.lat() ? e.latLng?.lat() : coords.latitude,
                        longitude: e.latLng?.lng() ? e.latLng?.lng() : coords.longitude
                      })
                    }}

                  />

                  <></>
                </GoogleMap>
              ) : (
                <></>
              )}
              {/* <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Latitude
            </IonLabel>
            <IonInput
              value={userDetail.latitude}
              placeholder="Latitude"
              type="text"
              onIonChange={(e) => {
                let user_data = userDetail;
                user_data.latitude = e.detail.value;
                setuserDetail(user_data);
              }}
            ></IonInput>
          </IonItem> */}
              {/* <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Longitude
            </IonLabel>
            <IonInput
              value={userDetail.longitude}
              placeholder="Longitude"
              type="text"
              onIonChange={(e) => {
                let user_data = userDetail;
                user_data.longitude = e.detail.value;
                setuserDetail(user_data);
              }}
            ></IonInput>
          </IonItem> */}
              {/* <IonItem className="ions_input w_280px">
                <capacitor-google-map ref={mapRef} style={{
                  display: 'inline-block',
                  width: 275,
                  height: 400
                }} />
              </IonItem> */}
            </div> : ""}

          <IonButtons slot="start" onClick={e => updateClick(e)}>
            <button type="button" className="btn-changes">
              Save Changes
              <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
              /></button>
          </IonButtons>


        </IonList>



      </IonContent>
    </IonPage>
  );
};
export default MyAccount;
