import { RouteComponentProps, useHistory } from "react-router-dom";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonDatetime,
  IonToolbar,
  useIonLoading,
  IonIcon,
  IonInput,
  IonItem,
  IonAvatar,
  IonImg,
  IonList,
  IonLabel,
  IonToast,
  IonLoading,
  useIonViewWillEnter,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import { RefresherEventDetail } from '@ionic/core';
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Geolocation } from "@capacitor/geolocation";
import "./Add_Employee.scss";
import { arrowBackOutline, eyeOutline, homeOutline, } from "ionicons/icons";
import Cookies from "universal-cookie";
import { base_url, user_token, sweetAlart } from "../App";
import PullToRefresh from "../components/PullToRefresh";

const Personal_Information: React.FC = (props) => {
  const key = (JSON.parse(localStorage.getItem('user_data') as string)) ? JSON.parse(localStorage.getItem('user_data') as string).user.role.key : null;

  const [passwordShown, setPasswordShown] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [showLoading, setShowLoading] = useState(false);
  const [shiftStartTime, setShiftStartTime] = useState('');
  const [shiftEndTime, setShiftEndTime] = useState('');
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const [coordinates, setCoordinates] = useState<any>("");
  const history = useHistory();

  const homeClick = () => {
    history.push("/Home");
  };
  const backButtonClick = () => {
    history.goBack();
  };

  interface userDetail {
    first_name: string,
    last_name: string,
    phone_number: string,
    adress: string,
    email: string,
    id: string,
    shift_starts_at: string,
    shift_ends_at: string
  }

  const [userDetail, setuserDetail] = useState<any>({
    first_name: " ",
    last_name: " ",
    phone_no: " ",
    email: "",
    password: "",
  });

  const cookies = new Cookies();
  var idd = cookies.get('id');

  useEffect(() => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + `/api/users/${idd}/show`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        let user = res.data.data;
        setuserDetail({
          first_name: user.first_name,
          phone_no: user.phone_no,
          last_name: user.last_name,
          email: user.email,

        });
        setShiftStartTime(user.shift_starts_at);
        setShiftEndTime(user.shift_ends_at);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateClick = (e: any) => {
    e.preventDefault();
    setShowLoading(true);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user_token.token
    }
    axios.post(base_url + '/api/auth/account-setting-update',
      {
        first_name: userDetail.first_name,
        last_name: userDetail.last_name,
        phone_no: userDetail.phone_no,
        email: userDetail.email,
        password: userDetail.password,
        shift_starts_at: shiftStartTime,
        shift_ends_at: shiftEndTime,
        user_id: idd,
      },
      {
        headers: headers,
      })
      .then(function (response) {
        if (response.data.status == true) {
          sweetAlart(response.data.message, 'success');
          // setAlertMessage(response.data.message);
          // setShowToast2(true);
          setShowLoading(false);
        }
      })
      .catch(function (error) {
        sweetAlart("Something went wrong, please try again.", 'error');
        console.log(error);
      });
  };

  const editClick = (id: any) => {
    history.push(`/account_update`, id);
  }

  const handleData = (userData: any) => {
    if (!userData) {
      person_info();
    }
  }
  const person_info = () => {
    setShowLoading(true);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user_token.token
    }
    axios.post(base_url + '/api/auth/account-setting-update',
      {
        first_name: userDetail.first_name,
        last_name: userDetail.last_name,
        phone_no: userDetail.phone_no,
        email: userDetail.email,
        password: userDetail.password,
        shift_starts_at: shiftStartTime,
        shift_ends_at: shiftEndTime,
        user_id: idd,
      },
      {
        headers: headers,
      })
      .then(function (response) {
        if (response.data.status == true) {
          sweetAlart(response.data.message, 'success');
          // setAlertMessage(response.data.message);
          // setShowToast2(true);
          setShowLoading(false);
        }
      })
      .catch(function (error) {
        sweetAlart("Something went wrong, please try again.", 'error');
        console.log(error);
      });
  }


  return (
    <IonPage>

      <IonContent fullscreen className="all_input">
        <PullToRefresh userDataProps={handleData} />
        {/* <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher> */}
        <IonHeader>
          <IonToolbar className="my_account">
            <div className="profile_header">
              <div className="back_btn">
                <IonButtons slot="start">
                  <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                  <h4>Personal information</h4>
                </IonButtons>
              </div>
              <div className="home_icon">
                <IonIcon onClick={homeClick} icon={homeOutline} />
              </div>
            </div>
            <div className="person_detail">
              <div className="profile_detail">
                <IonAvatar>
                  <IonImg src={userDetail.image ? userDetail.image_url : require("../assets/images/member.jpg").default} />
                </IonAvatar>
                <div className="contact_detail">
                  <strong>{userDetail.first_name + " " + userDetail.last_name}</strong>
                  <h5>{userDetail.email}</h5>
                </div>
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">First name</IonLabel>
            <IonInput value={userDetail.first_name} type="text"
              onIonChange={(e) => {
                let user_data = userDetail;
                user_data.first_name = e.detail.value;
                setuserDetail(user_data);
              }}></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Last Name
            </IonLabel>
            <IonInput
              placeholder="Last Name"
              type="text"
              value={userDetail.last_name}
              onIonChange={(e) => {
                let user_data = userDetail;
                user_data.last_name = e.detail.value;
                setuserDetail(user_data);
              }}
            // onIonChange={(e) => setLast_name(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Email Address
            </IonLabel>
            <IonInput
              // readonly
              placeholder="Email Address"
              type="email"
              value={userDetail.email}
              onIonChange={(e) => {
                let user_data = userDetail;
                user_data.email = e.detail.value;
                setuserDetail(user_data);
              }}
            // onIonChange={(e) => setEmailAddress(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Contact No
            </IonLabel>
            <IonInput
              // readonly
              placeholder="+92 xxx xxxxxxx"
              type="number"
              value={userDetail.phone_no}
              onIonChange={(e) => {
                userDetail.phone_no = e.detail.value;
                setuserDetail(userDetail);
              }}
            // onIonChange={(e) => setPhone_no(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Shift starting
            </IonLabel>
            <IonDatetime
              displayFormat="h:mm:a"
              value={shiftStartTime}
              onIonChange={(e) => { setShiftStartTime(e.detail.value!); }}
            ></IonDatetime>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Shift ending
            </IonLabel>
            <IonDatetime
              displayFormat="h:mm:a"
              value={shiftEndTime}
              onIonChange={(e) => { setShiftEndTime(e.detail.value!) }}
            ></IonDatetime>
          </IonItem>
          <IonButtons slot="start" onClick={e => updateClick(e)}>
            <button type="button" className="btn-changes">
              Save Changes
              <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
              /></button>
          </IonButtons>
        </IonList>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
        <IonToast
          isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          color={"primary"}
          duration={2000}
          position="middle"
          message={alertMessage}
        />
      </IonContent>
    </IonPage>
  );
};
export default Personal_Information;
