import { AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';
  export default function Chart() {
 
    const data = [
      {
        "name": "Jan",
        "Present": 24,
        "Absent": 2,
      },
      {
        "name": "Feb",
        "Present": 23,
        "Absent": 3,
      },
      {
        "name": "Mar",
        "Present": 21,
        "Absent": 5,
      },
      {
        "name": "April",
        "Present": 25,
        "Absent": 1,
      },
      {
        "name": "May",
        "Present": 22,
        "Absent": 4,
      },
    
    ]
    return (
     <div className='areachart'>
  <AreaChart width={370} height={150} data={data}
  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
  <defs>
    <linearGradient id="colorPresent" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
    <linearGradient id="colorAbsent" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
    </linearGradient>
  </defs>
  <XAxis dataKey="name" />
  <YAxis />
  <Tooltip />
  <Area type="monotone" dataKey="Present" stroke="#8884d8" fillOpacity={1} fill="url(#colorPresent)" />
  <Area type="monotone" dataKey="Absent" stroke="#82ca9d" fillOpacity={1} fill="url(#colorAbsent)" />
</AreaChart>
     </div>
    )
  }

