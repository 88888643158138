import {
  IonTabs,IonTabBar,IonTabButton,IonIcon,
  IonLabel,IonRouterOutlet,
} from "@ionic/react";
import {
  clipboardOutline,personOutline,documentTextOutline
} from "ionicons/icons";
import { Route, Redirect } from "react-router-dom";
import LeaveHistoryDetail from "./LeaveHistoryDetail";
import ProtectedRoute from "../ProtectedRoute";
import HistoryCheck from "./HistoryCheck";
import Personal_Information from "./Personal_Information";
const User_TabList: React.FC = () => (
  <IonTabs>
    <IonRouterOutlet>
      <ProtectedRoute exact path="/Personal_Information" component={Personal_Information} />
      <ProtectedRoute exact path="/HistoryCheck" component={HistoryCheck} />
      <ProtectedRoute exact path="/LeaveHistoryDetail" component={LeaveHistoryDetail} />
      <Route path="/" render={() => <Redirect to="/Employee_List" />} exact={true} />
    </IonRouterOutlet>
    <IonTabBar slot="bottom" >
      <IonTabButton tab="Leave History" href="/LeaveHistoryDetail">
        <IonIcon icon={clipboardOutline} />
        <IonLabel>Leave History </IonLabel>
      </IonTabButton>
      <IonTabButton tab="Attendance history" href="/HistoryCheck">
        <IonIcon icon={documentTextOutline} />
        <IonLabel>Attendance history</IonLabel>
      </IonTabButton>
      <IonTabButton tab="Personal information" href="/Personal_Information">
        <IonIcon icon={personOutline} />
        <IonLabel>Personal information</IonLabel>
      </IonTabButton>
    </IonTabBar>
  </IonTabs>

);

export default User_TabList;
