import { useHistory } from "react-router-dom";
import {
  IonButtons, IonContent, IonHeader, IonPage,
  IonDatetime, IonToolbar, IonIcon, IonInput,
  IonItem, IonList, IonLabel, IonToast, IonLoading, useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Add_Employee.scss";
import { arrowBackOutline, eyeOutline, homeOutline, } from "ionicons/icons";
import { base_url, sweetAlart } from "../App";
import { Toast } from "@capacitor/toast";
import Cookies from "universal-cookie";
import PullToRefresh from "../components/PullToRefresh";
const Add_Employee: React.FC = () => {
  let token = JSON.parse(localStorage.getItem("user_data") as string).token;
  const userData: any = JSON.parse(
    localStorage.getItem("user_data") as string
  ).user;

  const [addEmployee, setAddEmployee] = useState<any>({
    first_name: "",
    last_name: "",
    phone_no: "",
    email: "",
    password: "",
  })

  const [passwordShown, setPasswordShown] = useState(false);
  const [shift_Starts_At, setShift_Starts_At] = useState<string>(userData.shift_starts_at);
  const [shift_Ends_At, setShift_Ends_At] = useState<string>(userData.shift_ends_at);
  const [showLoading, setShowLoading] = useState(false);
  const history = useHistory();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const homeClick = () => {
    history.push("/Home");
  };
  const backButtonClick = () => {
    history.goBack();
  };

  // useIonViewWillEnter(() => {
  //   setShowLoading(true);
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token.token
  //   }
  //   axios
  //     .post(
  //       base_url + "/api/users/store",
  //       {
  //         first_name: addEmployee.first_name,
  //         last_name: addEmployee.last_name,
  //         phone_no: addEmployee.phone_no,
  //         email: addEmployee.email,
  //         password: addEmployee.password,
  //         shift_Starts_At,
  //         shift_Ends_At,
  //         user_id: userData.id,
  //         role_key: "user",
  //       },
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then(function (response) {
  //       let user = response.data.data;
  //       if (response.data.status == true) {
  //         setShowLoading(false);
  //         setAddEmployee({
  //           first_name: " ",
  //           last_name: " ",
  //           phone_no: " ",
  //           email: " ",
  //           password: " ",
  //         })
  //         sweetAlart(response.data.message, 'success');
  //       } else {
  //         setShowLoading(false);
  //         sweetAlart(response.data.message, 'error');
  //       }
  //     })
  //     .catch(function (error) {
  //       setShowLoading(false);
  //       sweetAlart("Something went wrong, please try again.", 'error');
  //       console.log(error);
  //     });
  // });

  const handleClick = (e: any) => {
    e.preventDefault();
    setShowLoading(true);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token.token
    }
    axios
      .post(
        base_url + "/api/users/store",
        {
          first_name: addEmployee.first_name,
          last_name: addEmployee.last_name,
          phone_no: addEmployee.phone_no,
          email: addEmployee.email,
          password: addEmployee.password,
          shift_Starts_At,
          shift_Ends_At,
          user_id: userData.id,
          role_key: "user",
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        let user = response.data.data;
        if (response.data.status == true) {
          setShowLoading(false);
          setAddEmployee({
            first_name: " ",
            last_name: " ",
            phone_no: " ",
            email: " ",
            password: " ",
          })
          sweetAlart(response.data.message, 'success');
        } else {
          setShowLoading(false);
          sweetAlart(response.data.message, 'error');
        }
      })
      .catch(function (error) {
        setShowLoading(false);
        sweetAlart("Something went wrong, please try again.", 'error');
        console.log(error);
      });
  };
  // console.log(addEmployee);

  // Pull to refresh code

  const handleData = (userData: any) => {
    if (!userData) {
      AddEmpUser();
    }
  }
  const AddEmpUser = () => {
    setShowLoading(true);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token.token
    }
    axios
      .post(
        base_url + "/api/users/store",
        {
          first_name: addEmployee.first_name,
          last_name: addEmployee.last_name,
          phone_no: addEmployee.phone_no,
          email: addEmployee.email,
          password: addEmployee.password,
          shift_Starts_At,
          shift_Ends_At,
          user_id: userData.id,
          role_key: "user",
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        let user = response.data.data;
        if (response.data.status == true) {
          setShowLoading(false);
          setAddEmployee({
            first_name: " ",
            last_name: " ",
            phone_no: " ",
            email: " ",
            password: " ",
          })
          sweetAlart(response.data.message, 'success');
        } else {
          setShowLoading(false);
          sweetAlart(response.data.message, 'error');
        }
      })
      .catch(function (error) {
        setShowLoading(false);
        sweetAlart("Something went wrong, please try again.", 'error');
        console.log(error);
      });
   
  }


  return (
    <IonPage>

      <IonContent fullscreen className="all_input">
        <PullToRefresh userDataProps={handleData}/>
        <IonHeader>
          <IonToolbar className="user_form">
            <div className="user-main-div">
              <div className="back_btn">
                <IonButtons slot="start">
                  <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                  <h4>Add employee</h4>
                </IonButtons>
              </div>
              <div className="home_icon">
                <IonIcon onClick={homeClick} icon={homeOutline} />
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonList lines="none">
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              First Name
            </IonLabel>
            <IonInput mode="ios"
              placeholder="First Name"
              type="text"
              value={addEmployee.first_name}
              onIonChange={(e) => {
                let add_employee = addEmployee;
                add_employee.first_name = e.detail.value;
                setAddEmployee(add_employee);
              }}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Last Name
            </IonLabel>
            <IonInput
              placeholder="Last Name"
              type="text"
              value={addEmployee.last_name}
              onIonChange={(e) => {
                let add_employee = addEmployee;
                add_employee.last_name = e.detail.value;
                setAddEmployee(add_employee);
              }}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Email Address
            </IonLabel>
            <IonInput
              placeholder="Email Address"
              type="email"
              value={addEmployee.email}
              onIonChange={(e) => {
                let add_employee = addEmployee;
                add_employee.email = e.detail.value;
                setAddEmployee(add_employee);
              }}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Contact No
            </IonLabel>
            <IonInput
              placeholder="+92 xxx xxxxxxx"
              type="number"
              value={addEmployee.phone_no}
              onIonChange={(e) => {
                let add_employee = addEmployee;
                add_employee.phone_no = e.detail.value;
                setAddEmployee(add_employee);
              }}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <div className="item_portion">
              <div className="input_field">
                <IonLabel position="stacked" className="label">
                  Password
                </IonLabel>
                <IonInput
                  placeholder="Password"
                  value={addEmployee.password}
                  type={passwordShown ? "text" : "password"}
                  onIonChange={(e) => {
                    let add_employee = addEmployee;
                    add_employee.password = e.detail.value;
                    setAddEmployee(add_employee);
                  }}
                ></IonInput>
              </div>
              <div className="eye_icon">
                <IonIcon onClick={togglePassword} icon={eyeOutline}>
                  {" "}
                </IonIcon>
              </div>
            </div>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Shift starting
            </IonLabel>
            <IonDatetime
              placeholder={shift_Starts_At ? "" : "Shift starting"}
              displayFormat="h:mm:a"
              value={shift_Starts_At}
              onIonChange={(e: any) => {
                setShift_Starts_At(e.detail.value)
              }}
            ></IonDatetime>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Shift ending
            </IonLabel>
            <IonDatetime
              placeholder={shift_Ends_At ? "" : "Shift ending"}
              displayFormat="h:mm:a"
              value={shift_Ends_At}
              onIonChange={(e: any) => {
                setShift_Ends_At(e.detail.value)
              }}
            ></IonDatetime>
          </IonItem>
          <IonButtons slot="start" onClick={(e) => handleClick(e)}>
            <button type="button" className="add_data">
              Add
            </button>
          </IonButtons>
        </IonList>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
      </IonContent>
    </IonPage>
  );
};
export default Add_Employee;
