import { Link, useHistory } from "react-router-dom";
import {
    IonButtons,IonContent,IonPage,IonIcon,
    IonList,IonFooter,
} from '@ionic/react';
import { base_url } from "../App"
import React, { useState } from "react";
import axios from "axios";
import "./Forgot_Password.scss"
import { mailUnreadOutline } from "ionicons/icons";
import PullToRefresh from "../components/PullToRefresh";
const Email_Check: React.FC = () => {
    const [email, setEmail] = useState<string>();
    const history = useHistory();
    const [showToast1, setShowToast1] = useState(false);

    const backClick = () => {
        history.goBack();
    }
    const handleClick = () => {
        history.push("/forgot_password");
        axios.post(base_url + '/api/auth/forgotPassword',
            {
                email: email,
            })
            .then(function (response) {
                if (response.data.status === true) {
                    // setShowToast1(true);
                    localStorage.clear();
                    localStorage.setItem('user_data', JSON.stringify(response.data));
                    history.push("/");
                }
                else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleData = (userData: any) => {
        if (!userData) {
          email_check();
        }
      }
      const email_check = () => {
       console.log((""));
      }
    return (
        <IonPage>
            <IonContent fullscreen className="all_input">
                <PullToRefresh userDataProps={handleData}/>
                <IonList className="email_check_instructions">
                    <div className="emial_unread_outline">
                        <IonIcon icon={mailUnreadOutline} />
                    </div>
                    <h3>Check your email</h3>
                    <p>We have sent a password recover instructions to your email.</p>
                    <IonButtons slot="start" onClick={e => handleClick()}>
                        {/* <IonToast
                            isOpen={showToast1}
                            message="Your Password Reset Successfully."
                            duration={1000}
                            color={"primary"}
                        /> */}
                        <button type="button" className="btn-changes">
                            Open your email
                        </button>
                    </IonButtons>
                    <Link to="/reset_password">Skip, I will confirm later</Link>
                </IonList>
            </IonContent>
            <IonFooter className="text_center ptlbr ion-no-border">
                <p>Do not receive your email check your spam filter and <a>try another email address.</a></p>
            </IonFooter>
        </IonPage>
    );
};

export default Email_Check;