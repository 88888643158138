import { useHistory } from "react-router-dom";
import {
  IonButtons, IonContent, IonHeader, IonPage,
  IonToolbar, IonIcon, useIonViewWillEnter,
} from "@ionic/react";
import React from "react";
import AttendanceHistory from "../components/AttendanceHistory";
import { arrowBackOutline, homeOutline } from "ionicons/icons";
import "./History.scss";
import PullToRefresh from "../components/PullToRefresh";

const History: React.FC = () => {

  const history = useHistory();
  const handleClick = () => {
    history.push("/Home");
  };


  return (
    <IonPage>

      <IonContent fullscreen className="user-his-content">
        <IonHeader>
          <IonToolbar className="history-toolbar">
            <div className="history-main-div">
              <div className="back_btn">
                <IonButtons slot="start">
                  <IonIcon icon={arrowBackOutline} onClick={handleClick} />
                  <h4>Attendance history</h4>
                </IonButtons>
              </div>
              <div className="home_icon">
                <IonIcon onClick={handleClick} icon={homeOutline} />
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <AttendanceHistory />
      </IonContent>
    </IonPage>
  );
};

export default History;
