import React from 'react'
import AnalogClock from '../components/AnalogClock'
import ClockSlider from '../components/ClockSlider'

export default function Clock() {
  return (
    <>
    {/* <ClockSlider /> */}
    <AnalogClock />
    </>
  )
}

