import {
  IonButtons, IonContent, IonHeader, IonIcon,
  IonPage, IonItem, IonLabel, IonToolbar,
  IonButton, IonList, IonAvatar, IonLoading, useIonViewWillEnter, useIonAlert, IonAlert, IonToast, IonRefresher, IonRefresherContent
} from "@ionic/react";
import {
  homeOutline, arrowBackOutline, addOutline, createOutline, trashOutline, atCircleSharp, atCircle,
} from "ionicons/icons";
import Cookies from 'universal-cookie';
import "./Employee_List.scss";
import { base_url } from "../App";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import PullToRefresh from "../components/PullToRefresh";
import { RefresherEventDetail } from '@ionic/core';
import { Event } from "ionicons/dist/types/stencil-public-runtime";
const Employee_List: React.FC = () => {
  const history = useHistory();
  const { token } = JSON.parse(
    localStorage.getItem("user_data") as string
  ).token;

  const [presentAlert] = useIonAlert();
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [showToast, setShowToast] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertMsg, setShowAlertMsg] = useState<any>('');
  const [myEmployee, setMyEmployee] = useState('');
  const [employeeId, setEmployeeId] = useState<any>('');

 

  interface userHistory {
    full_name: string;
    created_at: string;
    deleted_at: string;
    id: string;
    image: string;
    latitude: string;
    phone_no: string;
    updated_at: string;
    email: string;
    role: { name: string };
  }
  const [userHistory, setuserHistory] = useState([
    {
      full_name: "",
      created_at: "",
      deleted_at: "",
      id: "",
      image: "",
      image_url: "",
      latitude: "",
      phone_no: "",
      updated_at: "",
      role: { name: "" },
      email: "",
    },
  ]);
// Pull to refresh code start
  const handleData = (userData:any) => {
    if(!userData){
      EmployeeList();
    }
  };
  const EmployeeList = ()=>{
    setShowLoading(true);
    axios
      .post(
        base_url + "/api/users/all",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setuserHistory(res.data.data);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useIonViewWillEnter(() => {
    EmployeeList();
  }, []);

// Pull to refresh code end

  const addClick = () => {
    history.push("/Add_Employee");
  };
  const editClick = (id: any) => {
    history.push("/Personal_Information", id);
    const cookies = new Cookies();
    cookies.set('id', id, { path: '/' });
  };
  const backButtonClick = () => {
    history.goBack();
  };
  const deleteItem = (id: any) => {
    console.log(id);
    const newList = userHistory.filter((user: any) => user.id !== id);
    setuserHistory(newList)
  }

//   function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
//     event.detail.complete();
//     setTimeout(() => {
//         // Any calls to load data go here
//     }, 2000);
// }
  return (
    <IonPage>

      <IonContent fullscreen className="setting_to_page">
        <PullToRefresh userDataProps={handleData} />
        {/* <IonRefresher
        slot="fixed"
        pullFactor={0.5} pullMin={100} pullMax={200}
        onIonRefresh={handleRefresh}>
        <IonRefresherContent
          // pullingIcon={atCircle}
          pullingText="Pull to refresh"
          refreshingSpinner="crescent"
          refreshingText="Refreshing...">
        </IonRefresherContent>
      </IonRefresher> */}
        <IonHeader>
          <IonToolbar className="userlist-toolbar">
            <div className="userlist-main-div">
              <div className="back_btn">
                <IonButtons slot="start">
                  <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                  <h4>Employee List</h4>
                </IonButtons>
              </div>
              <div className="home_icon" onClick={backButtonClick}>
                <IonIcon icon={homeOutline} />
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
        />
        <div className="add_employee">
          <IonButton shape="round" className="add_user" onClick={addClick}>
            Add<IonIcon icon={addOutline} />
          </IonButton>
        </div>
        <IonList className="p-20">
          {userHistory.map((user, index) => {
            return (
              <div key={index} className="emplyee-parent" >
                <IonItem className="update_account">
                  <IonAvatar slot="start">
                    <img
                      src={user.image ? user.image_url : require("../assets/images/user.png").default}
                    />
                  </IonAvatar>
                  <IonLabel className="employee-content">
                    <h2>{user.full_name} </h2>
                    <h3>{user.email}</h3>
                    <p>{user.phone_no}</p>
                  </IonLabel>
                  <div className="edit_employee">
                    <IonIcon onClick={() => editClick(user.id)} icon={createOutline} />
                    <IonIcon className="del-icon" onClick={() => {
                      setShowAlert(true)
                      setEmployeeId(user.id)
                    }} icon={trashOutline} />
                    {/* onClick={()=> deleteItem(user.id)} */}
                    {/* <h4 onClick={() => editClick(user.id)}>Update</h4>
                    <h5 onClick={()=> deleteItem(user.id)}>Delete</h5> */}
                  </div>
                </IonItem>
                <br />
              </div>
            );
          })}
        </IonList>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='alert-css'
          header={'Confirm!'}
          message="Do you want to delete this!"
          buttons={[
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel: blah');
              }
            },
            {
              text: 'Yes',

              handler: () => {

                axios
                  .post(
                    base_url + `/api/users/${employeeId}/delete`,
                    {},
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res.status)
                    deleteItem(employeeId)
                    if (res.data.status === true) {
                      setShowLoading(false);
                      setAlertMessage(res.data.message);
                      setShowToast(true);
                    } else {
                      setShowLoading(false);
                      setShowError(true);
                      setAlertMessage(res.data.message);
                      setShowToast(true);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    setShowLoading(false);
                    setShowError(true);
                    setShowAlertMsg("Something went wrong! record not deleted");
                    setShowToast(true);
                  });
              }
            }
          ]}
        />
        <IonToast
          isOpen={showToast}
          position="middle"
          onDidDismiss={() => setShowToast(false)}
          color={showError ? "danger" : "primary"}
          message={alertMessage}
          // message="This user is delete"
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Employee_List;
