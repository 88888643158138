import {
  IonButtons,IonContent,IonIcon,IonItem,
  IonLabel,IonLoading,IonPopover,IonRadio,
  IonRadioGroup,IonToast, useIonViewWillEnter
} from "@ionic/react";
import axios from "axios";
import { base_url } from "../App";
import {useState } from "react";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import {calendarOutline,readerOutline,bedOutline,closeOutline
} from "ionicons/icons";

const LeaveHistoryData = () => {
  const history = useHistory();
  const cookies = new Cookies();
  var idd = cookies.get("id");
  const key = JSON.parse(localStorage.getItem("user_data") as string)
    ? JSON.parse(localStorage.getItem("user_data") as string).user.role.key
    : null;
  const [popoverState, setShowPopover] = useState(false);
  const [selected, setSelected] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [primaryKey, setPrimaryKey] = useState<any>();
  const [users, setUser] = useState([
    {
      id: "",
      from_date: "",
      created_at:"",
      status: "",
      to_date: "",
      reason: "",
      leaves_count: "",
      format_created_at:"",
      leave_type: { name: "" },
    },
  ]);

  useIonViewWillEnter(() => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + `/api/leave/show`,
        {
          id: idd,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setUser(res.data.data);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const leaveUpdateClick = () => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    axios
      .post(
        base_url + "/api/leave/updateLeaveStatus",
        {
          leave_status: selected,
          idd,
          primaryKey,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.data.status == true) {
          setUser(response.data.data);
          setStatusMessage(response.data.message)
          setShowPopover(false);
          setShowLoading(false);
          setShowToast1(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="wrapper-nawa">
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonToast
        isOpen={showToast1}
        message={statusMessage}
        position="middle"
        onDidDismiss={() => setShowToast1(false)}
        duration={2000}
        color={"primary"}
      />
      {key && key === "company" ? (
        <IonPopover
          cssClass="my-custom-class"
          isOpen={popoverState}
          onDidDismiss={() => setShowPopover(false)}
        >
          <IonContent className="overflow_hidden">
            <IonRadioGroup
              value={selected}
              onIonChange={(e) => setSelected(e.detail.value)}
            >
              <div className="text_align_end">
                <IonIcon
                  icon={closeOutline}
                  className="close_icon"
                  onClick={() => {
                    setShowPopover(false);
                  }}
                />
              </div>
              <IonItem lines="none">
                <IonLabel>Pending</IonLabel>
                <IonRadio slot="start" value="Pending" />
              </IonItem>
              <IonItem lines="none">
                <IonLabel>Approved</IonLabel>
                <IonRadio slot="start" value="Approved" />
              </IonItem>
              <IonItem lines="none">
                <IonLabel>Rejected</IonLabel>
                <IonRadio slot="start" value="Rejected" />
              </IonItem>
            </IonRadioGroup>
            <IonButtons
              className="leave_status_update"
              onClick={leaveUpdateClick}
            >
              update
            </IonButtons>
          </IonContent>
        </IonPopover>
      ) : (
        ""
      )}
      <div className="content-main-home calender-plugin history p-0">
        <div className="mb_30">
          {/* <h4 className="text_start">Yesterday</h4> */}
          {users.map((user, index) => {
            return (
              <div key={index}>
                <div>
                  <h4 className="text_start">
                      {user.format_created_at}</h4>
                </div>
                <div className="leave_detail_information">
                  <div className="Leave_deatil mt_30">
                    <div className="leave_deatail_show">
                      <div>
                        <IonIcon
                          className="leave_icon"
                          icon={calendarOutline}
                        />
                      </div>
                    </div>
                    <div className="ml_15">
                      <p className="m_none">Applied Duration</p>
                      <div className="leave_duration">
                        <div className="from_date">
                          <h4>{user.from_date}</h4>
                        </div>
                        <div className="to_date">
                          <h4 className="mr_13">to</h4>
                          <h4>{user.to_date}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Leave_deatil">
                    <div className="leave_deatail_show">
                      <div>
                        <IonIcon className="leave_icon" icon={readerOutline} />
                      </div>
                    </div>
                    <div>
                      <p className="mlp">Reason</p>
                      <div className="leave_duration">
                        <div className="to_date width">
                          <h4 className="mlp">{user.reason}</h4>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Leave_deatil">
                    <div className="leave_deatail_show">
                      <div>
                        <IonIcon className="leave_icon" icon={bedOutline} />
                      </div>
                    </div>
                    <div className="ml_15">
                      <p className="m_none">Type of Leave</p>
                      <div className="leave_duration">
                        <div className="to_date">
                          <h4>{user.leave_type.name}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="leave_status"
                    onClick={() => {
                      setShowPopover(true);
                      setPrimaryKey(user.id);
                    }}
                  >
                    <h4 className="status_text">{user.status}</h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* <IonButtons className='apply_leave' onClick={()=>leaveClick(idd)}>Apply for leave</IonButtons> */}
      </div>
    </div>
  );
};
export default LeaveHistoryData;
