import { Link, useHistory } from "react-router-dom";
import {
  IonButtons, IonContent, IonPage, IonIcon,
  IonList, IonFooter, IonHeader, IonToolbar, IonButton,
} from '@ionic/react';
import React, { useState } from "react";
import axios from "axios";
import "./CompanyLeaveApplication.scss"
import { arrowBackOutline, homeOutline } from "ionicons/icons";
const CompanyLeaveApplication: React.FC = () => {
  const [showAllApplications, setShowAllApplications] = useState(true);
  const history = useHistory();

  const backClick = () => {
    history.goBack();
  }
 

  const handleClick = () => {
    setShowAllApplications(prevState => !prevState);
  };

  // function backButtonClick(event: MouseEvent<HTMLIonIconElement, MouseEvent>): void {
  //   throw new Error("Function not implemented.");
  // }

  return (
    <IonPage>
       <IonHeader>
                    <IonToolbar className="setting-toolbar">
                        <div className="Forgot-main-div">
                            <div className="back_btn">
                                <IonButtons slot="start">
                                    <IonIcon icon={arrowBackOutline} onClick={backClick} />
                                    <h4>Forgot password</h4>
                                </IonButtons>
                            </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
      <IonContent fullscreen className="all_input">
        {/* <PullToRefresh userDataProps={handleData}/> */}
          <div className="leave-btns">
            {
              showAllApplications?(
<IonButton expand="full">All Leave Applications</IonButton>
              ) : (
                <IonButton expand="full">New Leave Applications</IonButton>
              )
            }
          </div>
      </IonContent>

    </IonPage>
  );
};

export default CompanyLeaveApplication;