
import { IonAvatar, IonIcon, IonInput, IonItem, IonLabel, IonList, useIonViewWillEnter } from '@ionic/react';
import { closeOutline, search } from 'ionicons/icons';
import React, { useState } from 'react'
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { base_url } from "../App";
import { useHistory } from "react-router-dom";
import PullToRefresh from '../components/PullToRefresh';
import data from "../components/MOCK_DATA.json"
import { log } from 'console';
const UserNameList = (props: any) => {

    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const history = useHistory();
    const [date, setDate] = useState<Date>(new Date());
    const [loading, setLoading] = useState(false);
    const [totalUser, setTotalUser] = useState();
    const [isUpdate, setIsUpdate] = useState<any>(false);
    const [users, setUser] = useState<any>([]);

    // useIonViewWillEnter(() => {
    //     setLoading(true)
    //     let currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    //     const { token } = JSON.parse(
    //         localStorage.getItem("user_data") as string
    //     ).token;
    //     axios
    //         .post(
    //             base_url + "/api/attendance/companyhistory",
    //             { date: currentDate },
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: "Bearer " + token,
    //                 },
    //             }
    //         )
    //         .then((res) => {
    //             setLoading(false)
    //             setUser(res.data.data);
    //             setTotalUser(res.data.TotalUser);
    //         })
    //         .catch((err) => {
    //             setLoading(false)
    //             console.log(err);
    //         });
    // }, []);

    // new call
    useIonViewWillEnter(() => {
        setLoading(true)
        const { token } = JSON.parse(
            localStorage.getItem("user_data") as string
        ).token;
        axios
            .post(
                base_url + "/api/users/get-company-employees",
                { name: 'asad' },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                }
            )
            .then((res) => {
                setUser(res.data.data);
                setLoading(false)
                for (let i = 0; i < res.data.data.length; i++) {
                    users.push(res.data.data[i])
                    // 
                }
                // setTotalUser(12);
            })
            .catch((err) => {
                setLoading(false)
                console.log(err);
            });
    }, []);

    const filteredData = users.filter((elem: any) => {

        //if no input the return the original
        if (props.input === '') {
            return elem;
            // setIsUpdate(true)
        }
        //return the item which contains the user input
        else {
            return elem.full_name.toLowerCase().includes(props.input)
        }
    })

    const onSearch = (searchTerm: any) => {
        // setValue(searchTerm);
        console.log("You search this name:", searchTerm);
    }

    const getUserId = (elem: any) => {
        console.log(elem);
        history.push("/employee_attendance_history")
    }

    return (
        <>
            <IonList className="update_setting_page">
                {filteredData.map((user: any, index: any) => {
                    return (
                        <IonItem key={index} lines="none" style={{ background: '#273685 !importan' }}>
                            <div className="report_detail" onClick={() => { getUserId(user.user_id) }}>
                                <div className="report_status">
                                    <IonAvatar className="employee_image">
                                        <img src={user.image_url} alt="" />
                                    </IonAvatar>
                                    <IonLabel>
                                        <div className="date_location_history">
                                            <h4>{user.full_name}</h4>
                                            <h5>{user.email} </h5>
                                        </div>
                                    </IonLabel>
                                </div>
                                <div className="attendance-status" style={{ textAlign: 'end' }}>
                                    <h4>{user.total_attendance_calculate.total_attendance} Days</h4>
                                    <h5>{user.total_attendance_calculate.total_percentage} %</h5>
                                </div>
                            </div>
                        </IonItem>
                    );
                })}
            </IonList>
        </>
    )
}

export default UserNameList

