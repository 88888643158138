import { useHistory } from "react-router-dom";
import {
    IonButtons, IonContent, IonHeader, IonPage,
    IonToolbar, IonIcon, IonInput, IonItem,
    IonList, IonLabel, IonLoading
} from '@ionic/react';
import { base_url } from "../App"
import React, { useState } from "react";
import axios from "axios";
import "./Forgot_Password.scss"
import { arrowBackOutline } from "ionicons/icons";
import PullToRefresh from "../components/PullToRefresh";
const Forgot_password: React.FC = () => {
    const [email, setEmail] = useState<string>();
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory();
    const backClick = () => {
        history.goBack();
    }
    const handleClick = () => {
        setShowLoading(true);
        axios.post(base_url + '/api/auth/email-send',
            {
                email: email,
            })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log(response);

                    // setShowToast1(true);
                    localStorage.clear();
                    localStorage.setItem('user_data', JSON.stringify(response.data));
                    setShowLoading(false);
                    history.push("/email_check");
                }
                else {
                    console.log(response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleData = (userData: any) => {
        if (!userData) {
            forget_password();
        }
    }
    const forget_password = () => {
        console.log("true");

    }
    return (
        <IonPage>

            <IonContent fullscreen className="all_input">
                <PullToRefresh userDataProps={handleData} />
                <IonHeader>
                    <IonToolbar className="setting-toolbar">
                        <div className="Forgot-main-div">
                            <div className="back_btn">
                                <IonButtons slot="start">
                                    <IonIcon icon={arrowBackOutline} onClick={backClick} />
                                    <h4>Forgot password</h4>
                                </IonButtons>
                            </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
                <IonList className="forget-list-bg">
                    <h3>Reset your password </h3>
                    <p>Enter your orignal email account accociated with your goggle account and we will
                        send an email with instructions to reset your password.</p>
                    <IonItem className="ions_input">
                        <IonLabel position="stacked" className="label">Email</IonLabel>
                        <IonInput placeholder="Email" type="text"
                            onIonChange={e => setEmail(e.detail.value!)}></IonInput>
                    </IonItem>
                    {/* <IonItem className="ions_input">
                        <IonLabel position="stacked" className="label">Confirm Password</IonLabel>
                        <IonInput placeholder="Confirm Password" type="password"
                                  onIonChange={e => setConfirmPassword(e.detail.value!)}></IonInput>
                    </IonItem> */}
                    <IonButtons slot="start" onClick={e => handleClick()}>
                        <IonLoading
                            cssClass='my-custom-class'
                            isOpen={showLoading}
                            onDidDismiss={() => setShowLoading(false)}
                            message={'Please wait...'}
                        />
                        <button type="button" className="btn-changes">
                            Send instructions
                        </button>
                    </IonButtons>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Forgot_password;