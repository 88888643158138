import { useHistory } from "react-router-dom";
import {
    IonButtons, IonContent, IonHeader, IonPage,
    IonToolbar, IonIcon, IonInput, IonItem,
    IonList, IonLabel, IonToast, IonLoading, useIonViewWillEnter, IonButton
} from '@ionic/react';
import axios from "axios";
import React, { useRef, useState } from "react";
import "./Employee_Attendance_History.scss"
import { arrowBackOutline, eyeOutline, homeOutline } from "ionicons/icons";
import PullToRefresh from "../components/PullToRefresh";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { base_url } from "../App";
const Employee_Attendance_History: React.FC = () => {
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState(null);
    const [showLoading, setShowLoading] = useState(true);
    const [leave_start_date, setLeave_start_date] = useState<string>();
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [showToast2, setShowToast2] = useState(false);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const[selectDate, setSelectDate] = useState<any>([{}])
    const onChange = (dates: [any, any]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const history = useHistory();
    const homeClick = () => {
        history.push("/Home");
    };
    const backButtonClick = () => {
        history.push("/Home");
    };

    let token = JSON.parse(localStorage.getItem("user_data") as string).token;

    const handleClick = (e: any) => {
        e.preventDefault();
        setShowLoading(true);
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token.token,
        };
        axios
            .post(
                base_url + "/api/attendance/filter-attendance-history",
                {
                    start_date: startDate,
                     ends_date: endDate,
                    //   key: leave_type,
                    //   Reason: text,
                    // status: "Pending",
                },
                {
                    headers: headers,
                }
            )
            .then(function (res:any) {
                console.log(res.data.data)
                if (res.data.data == true) {
                    setSelectDate(res.data.data)
                    setShowLoading(true);
                    setLeave_start_date("");
                    setAlertMessage(res.data.message);
                    setShowLoading(false);
                    setShowToast2(true);
                }else {
                    setShowLoading(false);
                    setShowError(true);
                    setAlertMessage(res.data.message);
                    setShowToast2(true);
                  }

            })
            .catch(function (error) {
                setShowLoading(false);
                console.log(error);
            });
    };

    return (
        <IonPage>
            <IonContent fullscreen className="all_input">
                <PullToRefresh />

                <IonHeader>
                    <IonToolbar className="change-password">
                        <div className="settings-main-div">
                            <div className="back_btn">
                                <IonButtons slot="start">
                                    <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                                    <h4>Employee Attendance History</h4>
                                </IonButtons>
                            </div>
                            <div className="home_icon">
                                <IonIcon onClick={homeClick} icon={homeOutline} />
                            </div>
                        </div>
                    </IonToolbar>
                </IonHeader>


                <div className="content-main-home calender-plugin history p-0 date-picker">
                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                    />
                    <div className="ok-btn">
                        <IonButton size="small" shape="round" onClick={(e) => handleClick(e)}>OK</IonButton>
                    </div>
                </div>


                <IonLoading
                    cssClass="my-custom-class"
                    isOpen={loading}
                    onDidDismiss={() => setLoading(false)}
                    message={"Please wait..."}
                    duration={3500}
                />

            </IonContent>
        </IonPage>
    );
};

export default Employee_Attendance_History;
