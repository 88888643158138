import {IonContent,IonPage} from '@ionic/react';
import React from "react";
import { useHistory } from "react-router-dom";
import LeaveHistoryData from '../components/LeaveHistoryData';
import LeaveHistoryHeader from '../components/LeaveHistoryHeader';
import "./Report.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./Home.scss";
import PullToRefresh from '../components/PullToRefresh';

const EmployeeLeaveHistoryDetail: React.FC = () => {
    const history = useHistory();
    const handleClick = () => {
        history.push("/Home");
    };
    return (
        <IonPage className="wrapper-1">
            <IonContent fullscreen>
                <PullToRefresh />
        <LeaveHistoryHeader/>    
                <LeaveHistoryData/>
            </IonContent>
        </IonPage>
    );
};

export default EmployeeLeaveHistoryDetail;
