import { useHistory } from "react-router-dom";
import {
    IonButtons, IonContent, IonHeader, IonPage,
    IonToolbar, IonIcon, IonInput, IonItem,
    IonList, IonLabel, IonToast, IonLoading,
    IonDatetime,
    useIonViewWillEnter
} from '@ionic/react';
import { base_url, user_token, sweetAlart } from "../App";
import React, { useRef, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "react-datepicker/dist/react-datepicker.css";
import "./Account_Update.scss"
import { arrowBackOutline, homeOutline } from "ionicons/icons";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import PullToRefresh from "../components/PullToRefresh";


interface coords {
    latitude: number;
    longitude: number;
}
const Account_Update: React.FC = () => {
    const key = (JSON.parse(localStorage.getItem('user_data') as string)) ? JSON.parse(localStorage.getItem('user_data') as string).user.role.key : null;
    const [showToast2, setShowToast2] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [showLoading, setShowLoading] = useState(false);
    const updateLastName = useRef(null);
    const [shiftStartTime, setShiftStartTime] = useState('');
    const [shiftEndTime, setShiftEndTime] = useState('');
    const [coords, setCoords] = useState<coords>({
        latitude: 0,
        longitude: 0,
    });
    const cookies = new Cookies();
    var idd = cookies.get('id');
    interface userDetail {
        first_name: string,
        last_name: string,
        phone_number: string,
        adress: string,
        email: string,
        id: string,
        shift_starts_at: string,
        shift_ends_at: string,
        latitude: number,
        longitude: number,
    }
    const [userDetail, setuserDetail] = useState<any>({
    });
    const history = useHistory();
    const homeClick = () => {
        history.push('/Home')
    }
    const backButtonClick = () => {
        history.goBack();
    }

    useIonViewWillEnter(() => {
        setShowLoading(true);
        const { token } = JSON.parse(
            localStorage.getItem("user_data") as string
        ).token;
        axios
            .post(
                base_url + `/api/users/${idd}/show`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                }
            )
            .then(async (res) => {
                let user = res.data.data;
                setCoords({
                    latitude: parseFloat(user.latitude),
                    longitude: parseFloat(user.longitude)
                })
                setuserDetail({
                    first_name: user.first_name,
                    phone_no: user.phone_no,
                    last_name: user.last_name,
                    email: user.email,
                    shiftStartTime: user.shift_starts_at,
                    ...coords
                });

                setShiftStartTime(user.shift_starts_at);
                setShiftEndTime(user.shift_ends_at);
                setShowLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);


    const apiKey = "AIzaSyBCafTKnC7aHB6eB32KXrmtoFGPJ1mq9Go";
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: apiKey,
    });

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        console.log("coors", { ...coords })
        const bounds = new window.google.maps.LatLngBounds({
            lat: coords.latitude,
            lng: coords.longitude,
        });
        map.fitBounds(bounds);
        setMap(map);
    }, []);



    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const handleClick = (e: any) => {
        e.preventDefault();
        console.log(userDetail)
        setShowLoading(true);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user_token.token
        }
        axios.post(base_url + '/api/auth/account-setting-update',
            {
                first_name: userDetail.first_name,
                last_name: userDetail.last_name,
                phone_no: userDetail.phone_no,
                email: userDetail.email,
                shift_starts_at: shiftStartTime,
                shift_ends_at: shiftEndTime,
                user_id: idd,
                longitude: JSON.stringify(coords.longitude),
                latitude: JSON.stringify(coords.latitude)

            },
            {
                headers: headers,
            })
            .then(function (response) {
                if (response.data.status == true) {
                    // setAlertMessage(response.data.message);
                    // setShowToast2(true);
                    history.push("/account_update");
                    setShowLoading(false);
                    sweetAlart(response.data.message, 'success');
                } else {
                    sweetAlart(response.data.message, 'warning');
                    setShowLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

     ////////// Pull to refresh code start

  const handleData = (userData: any) => {
    if (!userData) {
      attend_history();
    }
  }
  const attend_history = () => {
    setShowLoading(true);
        const { token } = JSON.parse(
            localStorage.getItem("user_data") as string
        ).token;
        axios
            .post(
                base_url + `/api/users/${idd}/show`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                }
            )
            .then(async (res) => {
                let user = res.data.data;
                setCoords({
                    latitude: parseFloat(user.latitude),
                    longitude: parseFloat(user.longitude)
                })
                setuserDetail({
                    first_name: user.first_name,
                    phone_no: user.phone_no,
                    last_name: user.last_name,
                    email: user.email,
                    shiftStartTime: user.shift_starts_at,
                    ...coords
                });

                setShiftStartTime(user.shift_starts_at);
                setShiftEndTime(user.shift_ends_at);
                setShowLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
  }
 
  ////////// Pull to refresh code end
    return (
        <IonPage>

            <IonContent fullscreen className="all_input">
                <PullToRefresh userDataProps={handleData}/>
                <IonHeader>
                    <IonToolbar className="setting-toolbar">
                        <div className="Account-main-div">
                            <div className="back_btn">
                                <IonButtons slot="start">
                                    <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                                    <h4>Account Update</h4>
                                </IonButtons>
                            </div>
                            <div className="home_icon">
                                <IonIcon onClick={homeClick} icon={homeOutline} />
                            </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
                <IonList>
                    <IonItem className="ions_input">
                        <IonLabel position="stacked" className="label">First name</IonLabel>
                        <IonInput value={userDetail.first_name} type="text"
                            onIonChange={(e) => {
                                let user_data = userDetail;
                                user_data.first_name = e.detail.value;
                                setuserDetail(user_data);
                            }}></IonInput>
                    </IonItem>
                    <IonItem className="ions_input">
                        <IonLabel position="stacked" className="label">Last Name</IonLabel>
                        <IonInput value={userDetail.last_name} placeholder={userDetail.last_name == '' ? "Last Name" : userDetail.last_name} type="text" ref={updateLastName}
                            onIonChange={(e) => {
                                userDetail.last_name = e.detail.value;
                                setuserDetail(userDetail);
                            }}></IonInput>
                    </IonItem>
                    <IonItem className="ions_input">
                        <IonLabel position="stacked" className="label">Contact No</IonLabel>
                        <IonInput value={userDetail.phone_no} placeholder={userDetail.phone_no == '' ? "Phone No" : userDetail.phone_no} type="number"
                            onIonChange={(e) => {
                                userDetail.phone_no = e.detail.value;
                                setuserDetail(userDetail);
                            }}></IonInput>
                    </IonItem>
                    <IonItem className="ions_input">
                        <IonLabel position="stacked" className="label">Email address</IonLabel>
                        <IonInput value={userDetail.email} placeholder={userDetail.email == '' ? "Email address" : userDetail.email} type="email"
                            onIonChange={(e) => {
                                userDetail.email = e.detail.value;
                                setuserDetail(userDetail);
                            }}></IonInput>
                    </IonItem>
                    {key && key === 'company' ?
                        <div>
                            <IonItem className="ions_input">
                                <IonLabel position="stacked" className="label">Shift starting</IonLabel>
                                <IonDatetime
                                    displayFormat="h:mm:a"
                                    value={shiftStartTime}
                                    onIonChange={
                                        (e) => {
                                            setShiftStartTime(e.detail.value!);
                                        }
                                    }
                                ></IonDatetime>
                            </IonItem>
                            <IonItem className="ions_input">
                                <IonLabel position="stacked" className="label">Shift ending</IonLabel>
                                <IonDatetime
                                    displayFormat="h:mm:a"
                                    value={shiftEndTime}
                                    onIonChange={
                                        (e) => {
                                            setShiftEndTime(e.detail.value!);
                                        }
                                    }
                                ></IonDatetime>
                            </IonItem>
                            <IonItem className="ions_input w_280px">
                                {isLoaded ? (
                                    <GoogleMap
                                        mapContainerStyle={{
                                            width: "100%",
                                            height: "400px",
                                        }}
                                        center={{ lat: coords.latitude, lng: coords.longitude }}
                                        zoom={10}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                        onClick={(e) => {
                                            setCoords({
                                                latitude: e.latLng?.lat() ? e.latLng?.lat() : coords.latitude,
                                                longitude: e.latLng?.lng() ? e.latLng?.lng() : coords.longitude
                                            })
                                        }}

                                    >
                                        {/* Child components, such as markers, info windows, etc. */}
                                        <Marker
                                            visible
                                            position={{ lat: coords.latitude, lng: coords.longitude }}
                                            draggable
                                            onDrag={(e) => {
                                                setCoords({
                                                    latitude: e.latLng?.lat() ? e.latLng?.lat() : coords.latitude,
                                                    longitude: e.latLng?.lng() ? e.latLng?.lng() : coords.longitude
                                                })
                                            }}

                                        />

                                        <></>
                                    </GoogleMap>
                                ) : (
                                    <></>
                                )}

                            </IonItem>
                        </div> : ""}
                    <IonButtons slot="start" onClick={e => handleClick(e)}>
                        <button type="button" className="btn-changes">
                            Save Changes
                            <IonLoading
                                cssClass='my-custom-class'
                                isOpen={showLoading}
                                onDidDismiss={() => setShowLoading(false)}
                                message={'Please wait...'}
                            />
                            {/* <IonToast
                                isOpen={showToast2}
                                onDidDismiss={() => setShowToast2(false)}
                                color={"primary"}
                                position="middle"
                                duration={2000}
                                message={alertMessage}
                            /> */}
                        </button>
                    </IonButtons>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Account_Update;