import "./ExploreContainer.css";
import {
  IonButton,
  IonLoading,
  IonIcon,
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonPage,
  useIonViewWillEnter,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import { RefresherEventDetail } from '@ionic/core';
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from "@capacitor/push-notifications";
import { Toast } from "@capacitor/toast";
import { Capacitor } from "@capacitor/core";
import {
  LocalNotifications,
} from "@capacitor/local-notifications";
import { Device } from "@capacitor/device";
import "font-awesome/css/font-awesome.min.css";
import { useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { base_url, sweetAlart } from "../App";


import { eyeOutline } from "ionicons/icons";
import "./login.scss";
// import { Geolocation } from "@capacitor/geolocation";
// import { Plugins } from "@capacitor/core";
// import PullToRefresh from "./PullToRefresh";

const Login: React.FC = () => {
  const history = useHistory()
  const nullEntry: any[] = [];

  const [login, setLogin] = useState<any>({
    email: "",
    password: "",
  })
  const [passwordShown, setPasswordShown] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [notifications, setnotifications] = useState(nullEntry);
  let [deviceInformation, setDeviceInformation] = useState<any>({});
  let [deviceToken, setDeviceToken] = useState<any>("");
  useIonViewWillEnter(() => {
    if (Capacitor.platform !== 'web') {
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
              showToast('Push Notification permission denied');
            }
            else {
              showToast('Push Notification permission granted');
              register();
            }
          });
        }
        else {
          deviceInfo();
          register();
        }
      });
    }
  }, []);

  const deviceInfo = async () => {
    const info = await Device.getInfo();
    setDeviceInformation(info);
  };

  const register = () => {
    console.log("Initializing HomePage");

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        // showToast('Push registration success');
        setDeviceToken(token);
      }
    );
    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }]);
        let notificationObj: any = {
          notifications: [
            {
              title: notification.title,
              body: notification.body,
              id: 1
            }
          ]
        };
        LocalNotifications.schedule(notificationObj);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
      }
    );
  }

  const showToast = async (msg: string) => {
    await Toast.show({
      text: msg
    })
  }
  const registerClick = () => {
    history.push("/register");
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const forgotPassword = () => {
    history.push("/Forgot_password");
  };
  const handleClick = () => {

    setShowLoading(true);
    // var header = 
    axios
      .post(base_url + "/api/auth/login",
        {
          email: login.email,
          password: login.password,
          model: deviceInformation.model,
          operating_system: deviceInformation.operatingSystem,
          os_version: deviceInformation.osVersion,
          platform: deviceInformation.platform,
          web_view_version: deviceInformation.webViewVersion,
          device_token: deviceToken.value,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          }
        }
      )
      .then(function (response) {
        if (response.data.status == true) {

          localStorage.clear();
          localStorage.setItem("user_data", JSON.stringify(response.data));
          localStorage.setItem("_token", "true");
          setShowLoading(false);
          setLogin({
            email: "",
            password: ""
          })
          history.replace("/");
        } else {
          sweetAlart(response.data.message, 'warning');
          setShowLoading(false);
        }
      })
      .catch(function (error) {
        sweetAlart("Something went wrong, please try again.", 'error');
        console.log(error);
        setShowLoading(false);
      });
  };

  const facebookClick = () => {
    axios
      .post(base_url + "/api/auth/loginWithFacebook", {})
      .then(function (response) {
        if (response.data.status == true) {
        } else {
          setAlertMessage(response.data.message);
          setShowToast1(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {

    setTimeout(() => {
      // Any calls to load data go here
      event.detail.complete();
      setLogin({
        email: "",
        password: ""
      })
    }, 2000);
  }

  return (
    <IonPage>
      <IonContent className="scroll-content">
        {/* <PullToRefresh /> */}
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div className="content-main">
          <div className="header">
            <IonImg src={require("../assets/images/logo.png").default} />
          </div>
          <div className="wrapper">
            <div className="heading">
              <h1>Welcome to Attender</h1>
              <p>Sign In Here!</p>
            </div>
            <div className="login-form">
              <IonItem lines="none">
                <IonInput
                  type="text"
                  placeholder="Email"
                  value={login.email}
                  onIonChange={(e) => {
                    let log_in = login;
                    log_in.email = e.detail.value!;
                    setLogin(log_in)
                  }}
                ></IonInput>
              </IonItem>
              <IonItem lines="none">
                <IonInput
                  placeholder="Password"
                  type={passwordShown ? "text" : "password"}
                  value={login.password}
                  onIonChange={(e) => {
                    let log_in = login;
                    log_in.password = e.detail.value;
                    setLogin(log_in)
                  }}
                ></IonInput>
                <IonIcon onClick={togglePassword} icon={eyeOutline}>
                </IonIcon>
              </IonItem>
              <h6 className="ion-text-end forgot" onClick={() => forgotPassword()}>Forgot Password</h6>
              {/* <div className="check_forgot">
                <div className="check">
                  <IonCheckbox slot="end" color="primary" />
                  <IonLabel>Keep me Signed in</IonLabel>
                </div>
                <a onClick={() => forgotPassword()}>
                  Forgot password
                </a>
              </div> */}
              <IonButton
                shape="round"
                expand="block"
                onClick={(e) => handleClick()}
              >
                SIGN IN
                <IonLoading
                  cssClass="my-custom-class"
                  isOpen={showLoading}
                  onDidDismiss={() => setShowLoading(false)}
                  message={"Please wait..."}
                />
                {/* <IonToast
                  isOpen={showToast1}
                  position="middle"
                  onDidDismiss={() => setShowToast1(false)}
                  color={"primary"}
                  message={alertMessage}
                  duration={2000}
                /> */}
              </IonButton>
              <div className="new-user">
                <p>
                  New to attender?{" "}
                  <a onClick={(e) => registerClick()}>Register as company</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
