import { IonAvatar, IonLoading, useIonViewWillEnter } from "@ionic/react";
import axios from "axios";
import { base_url,} from "../App";
import Cookies from "universal-cookie";
import {useState } from "react";
const EmployeeHistory=()=>{
  const cookies = new Cookies();
  var idd = cookies.get('id');
  const [loading, setLoading] = useState(false);

  interface userHistory {
    longitude: string;
    attendance_datetime: string;
    formatted_attendance_datetime: string;
    created_at: string;
    deleted_at: string;
    id: string;
    image: string;
    latitude: string;
    location: string;
    updated_at: string;
    user_id: string;
    image_url: string;
    distance: string;
    status: string;
    distance_in_meter: string;
    attandance_time: string;
  }
  const [userHistory, setuserHistory] = useState([
    {
      longitude: "",
      attendance_datetime: "",
      formatted_datetime: "",
      created_at: "",
      deleted_at: null,
      id: null,
      image: "",
      latitude: "",
      location: "",
      updated_at: "",
      user_id: null,
      image_url: "",
      distance: "",
      status: "",
      distance_in_meter: "",
      attandance_time: ""
    },
  ]);

  useIonViewWillEnter(() => {
    setLoading(true);
    axios.post(
        base_url + `/api/attendance/employee/history`,
        {
            id: idd
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('user_data') as string).token.token,
          },
        }
      )
      .then(async (res) => {

        let data = await res.data.data;
        if (data) {
          setuserHistory(data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log({ err: err.message })
      });
  }, []);
  return (
    <div className="history">
     <h3>Attendance History</h3>
     {userHistory.map((user, index) => {
            return (
              <div key={index}>
                <div className="history_detail">
                  <div className="history_status">
                    <IonAvatar className="employee_image">
                      <img src={
                        user.image_url
                        ? user.image_url
                        : require("../assets/images/user.png").default
                        } alt="" />
                    </IonAvatar>
                    <div className="date_location_history">
                      <h4>{user.formatted_datetime}</h4>
                      <h5>{user.distance_in_meter} meter</h5>
                      <h5>{user.attandance_time}</h5>
                    </div>
                  </div>
                  <IonLoading
                    cssClass="my-custom-class"
                    isOpen={loading}
                    onDidDismiss={() => setLoading(false)}
                    message={'Please wait...'}
                  />
                  <div className="attendance-status">
                    <h4>{user.status}</h4>
                  </div>
                </div>
              </div>
            );
          })}
  </div>
  );
}
export default EmployeeHistory;