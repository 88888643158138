import { useHistory } from "react-router-dom";
import {
    IonButtons, IonContent, IonHeader, IonPage,
    IonToolbar, IonIcon, IonInput, IonItem,
    IonList, IonLabel, IonToast, IonLoading
} from '@ionic/react';
import { base_url, } from "../App"
import React, { useRef, useState } from "react";
import axios from "axios";
import "./Forgot_Password.scss"
import { arrowBackOutline, eyeOutline, } from "ionicons/icons";
import PullToRefresh from "../components/PullToRefresh";
const Reset_Password: React.FC = () => {
    const [password, setPassword] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();
    const [showLoading, setShowLoading] = useState(false);
    const [showToast1, setShowToast1] = useState(false);
    const [passwordShown1, setPasswordShown1] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const history = useHistory();
    const updatePassword = useRef(null);
    const confirm = useRef(null);

    const togglePassword1 = () => {
        setPasswordShown1(!passwordShown1);
    };
    const togglePassword2 = () => {
        setPasswordShown2(!passwordShown2);
    };
    const backClick = () => {
        history.goBack();
    }
    const handleClick = () => {
        setShowLoading(true);
        axios.post(base_url + '/api/auth/forgotPassword',
            {
                password: password,
                confirmPassword: confirmPassword
            })
            .then(function (response) {
                if (response.data.status === true) {
                    setShowToast1(true);
                    localStorage.clear();
                    localStorage.setItem('user_data', JSON.stringify(response.data));
                    setShowLoading(false);
                    history.push("/login");
                }
                else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleData = (userData: any) => {
        if (!userData) {
          attend_history();
        }
      }
      const attend_history = () => {
        console.log("pass value");
      }
    
    return (
        <IonPage>
            
            <IonContent fullscreen className="all_input">
             <PullToRefresh userDataProps={handleData}/>
            <IonHeader>
                <IonToolbar className="setting-toolbar">
                    <div className="Forgot-main-div">
                        <div className="back_btn">
                            <IonButtons slot="start">
                                <IonIcon icon={arrowBackOutline} onClick={backClick} />
                                <h4>Create new password</h4>
                            </IonButtons>
                        </div>
                        {/* <div className="setting_icon">
                            <IonIcon  icon={settingsOutline}/>
                        </div> */}
                    </div>
                </IonToolbar>
            </IonHeader>
                <IonList>
                    <h3>Create new password </h3>
                    <p>Your new password must be different from previous used passwords.</p>
                    <span>Must be atleast 6 characters</span>
                    <IonItem className="ions_input">
                        <div className="item_portion">
                            <div className="input_field">
                                <IonLabel position="stacked" className="label">Password</IonLabel>
                                <IonInput placeholder="Password" value={password} type={passwordShown1 ? "text" : "password"} ref={updatePassword}
                                    onIonChange={e => setPassword(e.detail.value!)}></IonInput>
                            </div>
                            <div className="eye_icon">
                                <IonIcon onClick={togglePassword1} icon={eyeOutline}> </IonIcon>
                            </div>
                        </div>
                    </IonItem>
                    <span>Both passwords must match</span>
                    <IonItem className="ions_input">
                        <div className="item_portion">
                            <div className="input_field">
                                <IonLabel position="stacked" className="label">Confirm Password</IonLabel>
                                <IonInput placeholder="Confirm Password" value={confirmPassword} type={passwordShown2 ? "text" : "password"} ref={confirm}
                                    onIonChange={e => setConfirmPassword(e.detail.value!)}></IonInput>
                            </div>
                            <div className="eye_icon">
                                <IonIcon onClick={togglePassword2} icon={eyeOutline}> </IonIcon>
                            </div>
                        </div>
                    </IonItem>
                    <IonButtons slot="start" onClick={e => handleClick()}>
                        <IonLoading
                            cssClass='my-custom-class'
                            isOpen={showLoading}
                            onDidDismiss={() => setShowLoading(false)}
                            message={'Please wait...'}
                        />
                        <IonToast
                            isOpen={showToast1}
                            message="Your Password Reset Successfully."
                            duration={2000}
                            color={"primary"}
                        />
                        <button type="button" className="btn-changes">
                            Reset password
                        </button>
                    </IonButtons>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Reset_Password;