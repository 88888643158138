import { useHistory } from "react-router-dom";
import {
    IonButtons, IonContent, IonHeader, IonPage,
    IonToolbar, IonIcon, IonItem, IonAvatar,
    IonList, IonLabel, useIonViewWillEnter
} from '@ionic/react';
import { base_url } from "../App"
import React, { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "./Settings.scss"
import { arrowBackOutline, chevronForwardOutline, homeOutline } from "ionicons/icons";
import PullToRefresh from "../components/PullToRefresh";



const Settings: React.FC = () => {
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory();
    const [userDetail, setuserDetail] = useState({
        first_name: "",
        phone_no: "",
        last_name: "",
        email: "",
        id: "",
        shift_starts_at: "",
        shift_ends_at: ""
    });
    var idd = userDetail.id;

    const updateAccount = (id: any) => {
        history.push("/account_update", id);
        const cookies = new Cookies();
        cookies.set('id', id, { path: '/' });
    }

    useIonViewWillEnter(() => {
        setShowLoading(true);
        let token = JSON.parse(localStorage.getItem('user_data') as string).token
        axios.post(base_url + '/api/account-setting-get', {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.token
                }
            })
            .then(async (res) => {

                let data = await res.data.data
                setuserDetail(data)
                setShowLoading(false);
            })
            .catch((err) => console.log({ err: err.message }))
    }, []);
    const backButtonClick = () => {
        history.push("/Home");
    }
    const homeClick = () => {
        history.push("/Home");
    }
    const changeProfilePic = () => {
        history.push("/change_profile_picture")
    }

    const handleData = (userData: any) => {
        if (!userData) {
            setting();
        }
    }
    const setting = () => {
        setShowLoading(true);
        let token = JSON.parse(localStorage.getItem('user_data') as string).token
        axios.post(base_url + '/api/account-setting-get', {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.token
                }
            })
            .then(async (res) => {

                let data = await res.data.data
                setuserDetail(data)
                setShowLoading(false);
            })
            .catch((err) => console.log({ err: err.message }))
    }
    return (
        <IonPage>

            <IonContent fullscreen className="setting_to_page">
                <PullToRefresh userDataProps={handleData} />
                <IonHeader>
                    <IonToolbar className="setting-toolbar">
                        <div className="settings-main-div">
                            <div className="back_btn">
                                <IonButtons slot="start">
                                    <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                                    <h4>Settings</h4>
                                </IonButtons>
                            </div>
                            <div className="home_icon" onClick={homeClick}>
                                <IonIcon icon={homeOutline} />
                            </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
                <IonList className="update_setting_page">
                    <IonItem className="update_account" onClick={() => updateAccount(idd)}>
                        <IonAvatar slot="start">
                            <img src={require('../assets/images/account.jfif').default} />
                        </IonAvatar>
                        <IonLabel>Update Account</IonLabel>
                        <IonIcon icon={chevronForwardOutline} />
                    </IonItem>
                    <IonItem className="change_profile_image" onClick={() => changeProfilePic()}>
                        <IonAvatar slot="start">
                            <img src={require('../assets/images/profile.png').default} />
                        </IonAvatar>
                        <IonLabel>Change Profile Image</IonLabel>
                        <IonIcon icon={chevronForwardOutline} />
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Settings;
