import { useHistory } from "react-router-dom";
import {
  IonButtons, IonContent, IonHeader, IonPage,
  IonDatetime, IonToolbar, useIonLoading, IonIcon,
  IonItem, IonList, IonLabel, IonToast,
  IonLoading, IonSelect, IonSelectOption, useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import React, { useState } from "react";
import "./Leave_Form.scss";
import {
  arrowBackOutline,
  chevronDownOutline,
  homeOutline,
} from "ionicons/icons";
import { base_url } from "../App";
import PullToRefresh from "../components/PullToRefresh";

const User_Form: React.FC = () => {
  interface userHistory {
    name: string;
    key: string;
    id: string;
  }
  const [userHistory, setuserHistory] = useState([
    {
      name: "",
      key: "",
      id: "",
    },
  ]);

  useIonViewWillEnter(() => {
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;

    axios
      .post(
        base_url + "/api/leave-type/show",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setuserHistory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [leave_start_date, setLeave_start_date] = useState<string>();
  const [leave_ends_date, setLeave_ends_date] = useState<string>();
  const [leave_type, setLeaveType] = useState<string>();
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const history = useHistory();
  const homeClick = () => {
    history.push("/Home");
  };

  const backButtonClick = () => {
    history.goBack();
  };

  let token = JSON.parse(localStorage.getItem("user_data") as string).token;
  const userData: any = JSON.parse(
    localStorage.getItem("user_data") as string
  ).user;

  const handleClick = (e: any) => {
    e.preventDefault();
    setShowLoading(true);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.token,
    };
    axios
      .post(
        base_url + "/api/leave/save",
        {
          leave_start_date,
          leave_ends_date,
          key: leave_type,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.data.status == true) {
          setShowLoading(true);
          setLeave_start_date("");
          history.push("/Leave_History");
          setShowToast1(true);
        } else {
          setShowLoading(false);
          setAlertMessage(response.data.message);
          setShowToast2(true);
        }
      })
      .catch(function (error) {
        setShowLoading(false);
        console.log(error);
      });
  };

  
const handleData = (userData: any) => {
  if (!userData) {
    leave_form();
  }
}
const leave_form = () => {
 console.log("true");
 
}
  return (
    <IonPage>
      <IonContent fullscreen className="all_input">
        <PullToRefresh userDataProps={handleData}/>
        <IonHeader>
          <IonToolbar className="user_form">
            <div className="user-main-div">
              <div className="back_btn">
                <IonButtons slot="start">
                  <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                  <h4>Apply leave</h4>
                </IonButtons>
              </div>
              <div className="home_icon">
                <IonIcon onClick={homeClick} icon={homeOutline} />
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Leave Type
            </IonLabel>
            <IonSelect
              placeholder="Leave Type"
              onIonChange={(e) => setLeaveType(e.detail.value!)}
            >
              {userHistory.map((user, index) => {
                return (
                  <div key={index}>
                    <IonSelectOption value={user.key}>
                      {user.name}
                    </IonSelectOption>
                  </div>
                );
              })}
            </IonSelect>
            <IonIcon className="chevronDownOutline" icon={chevronDownOutline} />
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Leave Start Date
            </IonLabel>
            <IonDatetime
              displayFormat="DD-MM-YYYY"
              placeholder=" Leave Start Date"
              value={leave_start_date}
              onIonChange={(e) => setLeave_start_date(e.detail.value!)}
            ></IonDatetime>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Leave Ends Date
            </IonLabel>
            <IonDatetime
              displayFormat="DD-MM-YYYY"
              placeholder=" Leave Ends Date"
              value={leave_ends_date}
              onIonChange={(e) => setLeave_ends_date(e.detail.value!)}
            ></IonDatetime>
          </IonItem>
          <IonButtons slot="start" onClick={(e) => handleClick(e)}>
            <button type="button" className="add_data">
              Apply for leave
              <IonLoading
                cssClass="my-custom-class"
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Please wait..."}
                duration={1000}
              />
              <IonToast
                isOpen={showToast1}
                message="Apply for leave Successfully."
                duration={1500}
                color={"primary"}
              />
              <IonToast
                isOpen={showToast2}
                onDidDismiss={() => setShowToast2(false)}
                color={"primary"}
                duration={1500}
                message={alertMessage}
              />
            </button>
          </IonButtons>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
export default User_Form;
