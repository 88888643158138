import {
    IonButtons, IonContent, IonHeader, IonProgressBar, IonIcon,
    IonListHeader, IonList, IonAvatar, IonPage, IonItem, IonLabel,
    IonToolbar, IonLoading, useIonViewWillEnter, IonButton
} from '@ionic/react';
import { homeOutline, arrowBackOutline } from "ionicons/icons";
import axios from 'axios';
import "./Report.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Home.scss";
import { base_url } from "../App";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PullToRefresh from '../components/PullToRefresh';

const Report: React.FC = () => {

    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const history = useHistory();
    const [date, setDate] = useState<Date>(new Date());
    const [loading, setLoading] = useState(false);
    const [totalUser, setTotalUser] = useState();
    const [users, setUser] = useState([
        {
            distance_in_meter: "",
            attandance_time: '',
            created_at: "",
            deleted_at: "",
            user_id: "",
            image_url: "",
            status: "",
            phone_no: "",
            updated_at: "",
            role: { name: "" },
            email: "",
            formatted_datetime: '',
            user: { full_name: '' }
        },
    ]);
    useIonViewWillEnter(() => {
        setLoading(true)
        let currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        const { token } = JSON.parse(
            localStorage.getItem("user_data") as string
        ).token;
        axios
            .post(
                base_url + "/api/attendance/companyhistory",
                { date: currentDate },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                }
            )
            .then((res) => {
                setLoading(false)
                setUser(res.data.data);
                setTotalUser(res.data.TotalUser);
            })
            .catch((err) => {
                setLoading(false)
                console.log(err);
            });
    }, []);
    const handleClick = () => {
        history.push("/Home");
    };
    const historyHandle = () => {
        history.push("/employee_attendance_detail");
      };
    ////////// Pull to refresh code start

    const handleData = (userData: any) => {
        if (!userData) {
            report();
        }
    }
    const report = () => {
        setLoading(true)
        let currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        const { token } = JSON.parse(
            localStorage.getItem("user_data") as string
        ).token;
        axios
            .post(
                base_url + "/api/attendance/companyhistory",
                { date: currentDate },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                }
            )
            .then((res) => {
                setLoading(false)
                setUser(res.data.data);
                setTotalUser(res.data.TotalUser);
            })
            .catch((err) => {
                setLoading(false)
                console.log(err);
            });
    }

    ////////// Pull to refresh code end
    return (
        <IonPage className="wrapper-1">
            <IonContent fullscreen>
                <PullToRefresh userDataProps={handleData} />
                <IonHeader className="ion-no-border p-0">
                    <IonToolbar className="report-toolbar">
                        <div className="report-main-div">
                            <div className="back_btn">
                                <IonButtons slot="start">
                                    <IonIcon icon={arrowBackOutline} onClick={handleClick} />
                                    <h4>Employee Attendance</h4>
                                </IonButtons>
                            </div>
                            <div className="home_icon">
                                <IonIcon onClick={handleClick} icon={homeOutline} />
                            </div>
                        </div>
                        <div className="attendance-bar">
                            <IonProgressBar
                                className="ionProgresBar"
                                value={users.length / 34}
                            />
                            <p>{users.length} out of {totalUser} presence</p>
                        </div>
                    </IonToolbar>

                </IonHeader>
                <div className="wrapper-nawa">
                    
                    <div className="content-main-home calender-plugin history p-0">
                    <div className="emp-history ion-text-end">
                               <IonButton size="small" shape='round' onClick={historyHandle}>Employee Attandance History</IonButton>
                               </div>
                            <div className='ion-padding-bottom'> 
                        <DatePicker
                            selected={startDate}
                            onChange={async (date: Date) => {
                                setDate(date);
                                setLoading(true)
                                let currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                                axios
                                    .post(
                                        base_url + "/api/attendance/companyhistory",
                                        { date: currentDate },
                                        {
                                            headers: {
                                                "Content-Type": "application/json",
                                                Authorization:
                                                    "Bearer " +
                                                    JSON.parse(localStorage.getItem("user_data") as string)
                                                        .token.token,
                                            },
                                        }
                                    )
                                    .then(async (res) => {
                                        let data = await res.data.data;
                                        if (data) {
                                            setLoading(false);
                                            setUser(data);
                                        }
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        console.log({ err: err.message });
                                    });
                            }}
                            inline
                        />
                        </div>  
                        <div>
                            <IonList className="update_setting_page">
                              

                                {/* <p className='ion-padding-bottom ion-text-start ion-padding-start comp-attend'>
                                    COMPANY ATTENDANCE </p> */}
                                {users.map((user, index) => {
                                    return (
                                        <IonItem key={index} lines="none" style={{ background: '#273685 !importan' }}>
                                            <div className="report_detail">
                                                <div className="report_status">
                                                    <IonAvatar className="employee_image">
                                                        <img src={user.image_url} alt="" />
                                                    </IonAvatar>
                                                    <IonLabel>
                                                        <div className="date_location_history">
                                                        <h5>{user.attandance_time}</h5>
                                                            <h4>{user.user.full_name}</h4>
                                                           
                                                        </div>
                                                    </IonLabel>
                                                </div>
                                                <div className="attendance-status" style={{ textAlign: 'end' }}>
                                                    <h4>{user.status}</h4>
                                                    <h5>{user.distance_in_meter} m</h5>
                                                </div>
                                            </div>
                                        </IonItem>
                                    );
                                })}
                            </IonList>
                        </div>
                    </div>
                </div>
                <IonLoading
                    cssClass="my-custom-class"
                    isOpen={loading}
                    onDidDismiss={() => setLoading(false)}
                    message={'Please wait...'}
                    duration={3500}
                />
            </IonContent>
        </IonPage>
    );
};

export default Report;
