import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './progressBar.scss'

const ProgressBar = () => {
  const Totalvalue = 0.83;
  const present = 0.80;
  const absent = 0.20;
  return (
    <div className='progresBar-main'>
      <div>
        <div style={{ width: 75 }}>
          <CircularProgressbar value={83} text={`${Totalvalue * 100}%`}
            styles={buildStyles({
              textColor: "#000000",
              pathColor: "#0163F9",
            })}
          />
        </div>
        <span className='attandance-status'>Average</span>
      </div>
      <div>
        <div style={{ width: 75 }}>
          <CircularProgressbar value={80} text={`${present * 100}%`}
            styles={buildStyles({
              textColor: "#000000",
              pathColor: "#12CF8A",
            })}
          />
        </div>
        <span className='attandance-status'>Present</span>
      </div>
      <div>
        <div style={{ width: 75 }}>
          <CircularProgressbar value={20} text={`${absent * 100}%`}
            styles={buildStyles({
              textColor: "#000000",
              pathColor: "#FC3B3D",
            })} />
        </div>
        <span className='attandance-status'>Absent</span>
      </div>
    </div>
  )
}

export default ProgressBar
