import { IonHeader, IonContent, IonToolbar, IonIcon, IonPage, IonRow, IonCol, IonImg } from '@ionic/react';
import Chart from '../components/Chart';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import './Stat.scss'
import { accessibilityOutline, arrowBackOutline } from "ionicons/icons";
import ProgressBar from '../components/ProgressBar';

import employeeImg from '../assets/images/profile.png';
import PullToRefresh from '../components/PullToRefresh';

const Stat = () => {
  const history = useHistory();
  const homeClick = () => {
    history.push("/Home");
  }

  const handleData = (userData: any) => {
    if (!userData) {
      stat();
    }
  }
  const stat = () => {
    console.log("pass valuex");

  }

  return (
    <div>
      <IonPage>

        <IonContent >
          <PullToRefresh userDataProps={handleData} />
          <IonHeader>
            <IonToolbar className="stat-toolbar">
              <span className="backArrow">
                <Link to="/Home" className="backArrowIcon"><IonIcon icon={arrowBackOutline} onClick={homeClick} /></Link>
              </span>
              <span>Stat</span>
            </IonToolbar>
          </IonHeader>
          <div className="stat-wrapper">
            <div className="employee-card">
              <div className="employee-img"><IonImg src={employeeImg} /></div><div className="employee-name"><span>Alim</span><span>alim@gmail.com</span></div>
            </div>
            <Chart />
            <ProgressBar />
            <IonRow className='stat-row'>
              <IonCol className='stat-col' size="4">
                <div className="col-inner darkCyan">
                  <IonIcon className="present-icon" icon={accessibilityOutline} />
                  <div>
                    <span>83%</span>
                    <p>Present</p>
                  </div>
                </div>
              </IonCol>
              <IonCol className='stat-col' size="4">
                <div className="col-inner darkSlateGray">
                  <IonIcon className="present-icon" icon={accessibilityOutline} />
                  <div>
                    <span>20%</span>
                    <p>Absent</p>
                  </div>
                </div>
              </IonCol>
            </IonRow>
            <IonRow className='stat-row'>
              <IonCol className='stat-col' size="4">
                <div className="col-inner darkCyan">
                  <IonIcon className="present-icon" icon={accessibilityOutline} />
                  <div>
                    <span>80%</span>
                    <p>Present</p>
                  </div>
                </div>
              </IonCol>
              <IonCol className='stat-col' size="4">
                <div className="col-inner darkSlateGray">
                  <IonIcon className="present-icon" icon={accessibilityOutline} />
                  <div>
                    <span>20%</span>
                    <p>Absent</p>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </div>
        </IonContent>
      </IonPage>
    </div>
  )
}

export default Stat
