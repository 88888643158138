import {
  IonButtons, IonContent, IonHeader, IonIcon,
  IonPage, IonLabel, IonToolbar, IonList,
  IonLoading, IonRadioGroup, IonRadio, IonRow,
  IonCol, IonAvatar, IonImg, IonButton, useIonViewWillEnter,
} from "@ionic/react";
import {
  homeOutline, arrowBackOutline,
} from "ionicons/icons";
import "./Employee_Manually_Attendance.scss";
import "./Employee_List.scss";
import { base_url } from "../App";
import { useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import PullToRefresh from "../components/PullToRefresh";

const Employee_Manually_Attendance: React.FC = () => {
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [selected2, setSelected2] = useState<any>([]);
  const [date, setDate] = useState(new Date());
  const [employeeHistory, setEmployeeHistory] = useState([
    {
      id: "",
      user_id: "",
      image: "",
      image_url: "",
      created_at: "",
      deleted_at: "",
      status: "",
      phone_no: "",
      updated_at: "",
      total_attendance_current_month: "",
      total_days_in_current_month: "",
      role: { name: "" },
      user: {
        image: "",
        image_url: "",
        full_name: "",
        email: "",
      },
      latitude: "",
    },
  ]);

  useIonViewWillEnter(() => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    let currentDate = `${date.getFullYear()}-${date.getMonth() + 1
      }-${date.getDate()}`;
    axios
      .post(
        base_url + "/api/attendance/todayCompanyHistory",
        {
          currentDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setEmployeeHistory(res.data.data);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const backButtonClick = () => {
    history.push("/home");
  };
  const setOptions = (optionsData: any) => {
    if (optionsData.user_id != "") {
      selected2.push(optionsData);
      setSelected2(selected2);
    }
  };

  let token = JSON.parse(localStorage.getItem("user_data") as string).token;
  const handleClick = (e: any) => {
    e.preventDefault();
    setShowLoading(true);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.token,
    };
    axios
      .post(
        base_url + "/api/attendance/attendanceStatusUpdate",
        {
          selected_data: selected2,
        },
        {
          headers: headers,
        }
      )
      .then(function (res) {
        setEmployeeHistory(res.data.data);
        setShowLoading(false);
      })
      .catch(function (error) {
        setShowLoading(false);
        console.log(error);
      });
  };

  
const handleData = (userData: any) => {
  if (!userData) {
    manually_attandance();
  }
}
const manually_attandance = () => {
  setShowLoading(true);
  const { token } = JSON.parse(
    localStorage.getItem("user_data") as string
  ).token;
  let currentDate = `${date.getFullYear()}-${date.getMonth() + 1
    }-${date.getDate()}`;
  axios
    .post(
      base_url + "/api/attendance/todayCompanyHistory",
      {
        currentDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((res) => {
      setEmployeeHistory(res.data.data);
      setShowLoading(false);
    })
    .catch((err) => {
      console.log(err);
    });
}

  return (
    <IonPage>

      <IonContent fullscreen className="setting_to_page">
        <PullToRefresh userDataProps={handleData}/>
        <IonHeader>
          <IonToolbar className="userlist-toolbar">
            <div className="userlist-main-div">
              <div className="back_btn">
                <IonButtons slot="start">
                  <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                  <h4>Employee manual attendance</h4>
                </IonButtons>
              </div>
              <div className="home_icon" onClick={backButtonClick}>
                <IonIcon icon={homeOutline} />
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
        <IonList>
          {employeeHistory.map((item, index) => {
            // if(item.image_url !== null){
            //   setProfileImageUrl(item.image_url);
            //   // console.log('image');
            // }
            return (
              <div key={index} className="employee_attendance_list">
                <div className="emplyee_attendance_detail">
                  <div className="employee_profiel_info">
                    <IonAvatar>
                      <IonImg
                        src={
                          item.image_url
                            ? item.image_url
                            : require("../assets/images/user.png").default
                        }
                      />
                    </IonAvatar>
                    <div className="employee_text">
                      <IonLabel>{item.user.full_name}</IonLabel>
                      <p>{item.user.email}</p>
                    </div>
                  </div>
                  <IonLabel className="number_of_attendance">{item.total_attendance_current_month}/{item.total_days_in_current_month}</IonLabel>
                </div>
                <div className="mtlbr">
                  <IonRadioGroup
                    value={item.status}
                    className="attendance_options"
                    onIonChange={(e) => {
                      let data = {
                        id: item.id,
                        user_id: item.user_id,
                        status: e.detail.value,
                      };
                      setOptions(data);
                    }}
                  >
                    <IonRow>
                      <IonCol className="attendance_status" size="3">
                        <IonRadio value="Present" />
                        <IonLabel>Present</IonLabel>
                      </IonCol>
                      <IonCol className="attendance_status" size="3">
                        <IonRadio mode="md" value="Absent" />
                        <IonLabel>Absent</IonLabel>
                      </IonCol>
                      <IonCol className="attendance_status" size="3">
                        <IonRadio mode="md" value="Late" />
                        <IonLabel>Late</IonLabel>
                      </IonCol>
                      <IonCol className="attendance_status" size="3">
                        <IonRadio mode="md" value="Leave" />
                        <IonLabel>Leave</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonRadioGroup>
                </div>
              </div>
            );
          })}
        </IonList>
        <IonButton className="w_100 prl_10" onClick={handleClick}>submit</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Employee_Manually_Attendance;
