import {
    IonButtons, IonContent, IonHeader, IonProgressBar, IonIcon,
    IonListHeader, IonList, IonAvatar, IonPage, IonItem, IonLabel,
    IonToolbar, IonLoading, useIonViewWillEnter, IonSelect, IonSelectOption, IonInput, IonSearchbar, IonImg
} from '@ionic/react';
import { homeOutline, arrowBackOutline, closeOutline, search, searchOutline } from "ionicons/icons";
import axios from 'axios';
import "./Employee_Attendance_Detail.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Home.scss";
import { base_url } from "../App";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PullToRefresh from '../components/PullToRefresh';
import data from "../components/MOCK_DATA.json"
import UserNameList from '../components/UserNameList';

const Employee_Attendance_Detail: React.FC = () => {

    const userList = [
        { first_name: 'Asad' },
        { first_name: 'Saim' },
        { first_name: 'Mehboob' },
        { first_name: 'Mehboob' },
        { first_name: 'Faheem' },
    ];
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const history = useHistory();
    const [date, setDate] = useState<Date>(new Date());
    const [loading, setLoading] = useState(false);
    const [totalUser, setTotalUser] = useState();
    const [inputValue, setInputValue] = useState("");
    const [value, setValue] = useState("");

    // const [searchText, setSearchText] = useState('');
    const [isUpdate, setIsUpdate] = useState<any>(false);
    // const [users, setUser] = useState([
    //     {
    //         distance_in_meter: "",
    //         attandance_time: '',
    //         created_at: "",
    //         deleted_at: "",
    //         user_id: "",
    //         image_url: "",
    //         status: "",
    //         phone_no: "",
    //         updated_at: "",
    //         role: { name: "" },
    //         email: "",
    //         formatted_datetime: '',
    //         user: { full_name: '' }
    //     },
    // ]);
    // useIonViewWillEnter(() => {
    //     setLoading(true)
    //     let currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    //     const { token } = JSON.parse(
    //         localStorage.getItem("user_data") as string
    //     ).token;
    //     axios
    //         .post(
    //             base_url + "/api/attendance/companyhistory",
    //             { date: currentDate },
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: "Bearer " + token,
    //                 },
    //             }
    //         )
    //         .then((res) => {
    //             setLoading(false)
    //             setUser(res.data.data);
    //             setTotalUser(res.data.TotalUser);
    //         })
    //         .catch((err) => {
    //             setLoading(false)
    //             console.log(err);
    //         });
    // }, []);
    // useIonViewWillEnter(() => {
    //     listFilterHandler();
    //   });
    const handleClick = () => {
        history.push("/Home");
    };

    const onSearch = (searchTerm: any) => {
        if (searchTerm == 'search') {
            console.log("You search this name:", searchTerm)
            setIsUpdate(true);
        } else if (searchTerm == 'cross') {
            console.log("You empty this input")
            setIsUpdate(false);
            setValue("")
        }
    }

    /////////////////// new search code /////////////////////

    let inputHandler = (e: any) => {
        //convert input text to lower case
        //filter code 
        var lowerCase = e.target.value.toLowerCase();
        setValue(lowerCase);
        //filter code 
    };

    // Pull to refresh code start

    const handleData = (userData: any) => {
        if (!userData) {
            employeeHistory();
        }
    };

    const employeeHistory = () => {
        setLoading(true)
        let currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        const { token } = JSON.parse(
            localStorage.getItem("user_data") as string
        ).token;
        axios
            .post(
                base_url + "/api/attendance/companyhistory",
                { date: currentDate },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                }
            )
            .then((res) => {
                setLoading(false)
                // setUser(res.data.data);
                setTotalUser(res.data.TotalUser);
            })
            .catch((err) => {
                setLoading(false)
                console.log(err);
            });
    }

    useIonViewWillEnter(() => {
        employeeHistory();
    }, []);


    // Pull to refresh code end
    return (
        <IonPage className="wrapper-1">

            <IonContent fullscreen>
                <PullToRefresh />
                <IonHeader className="ion-no-border p-0">
                    <IonToolbar className="report-toolbar">
                        <div className="report-main-div">
                            <div className="back_btn">
                                <IonButtons slot="start">
                                    <IonIcon icon={arrowBackOutline} onClick={handleClick} />
                                    <h4>Employee Attendance Filter</h4>
                                </IonButtons>
                            </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
                <div className="wrapper-nawa">
                    <div className="content-main-home calender-plugin history p-0">
                        <div className='userName-search'>
                            <IonList lines='none' mode='ios'>
                                <IonItem className='search-item'>
                                    <IonInput placeholder="Enter text" className="input" value={value} onIonChange={inputHandler}></IonInput>
                                </IonItem>
                            </IonList>
                            <div className="home_icon">
                                {
                                    isUpdate ? <IonIcon icon={closeOutline} onClick={() => { onSearch('cross') }} className="search-icon" /> : <IonIcon icon={search} onClick={() => { onSearch('search') }} className="search-icon" />
                                }
                            </div>
                        </div>
                        <div>

                            <UserNameList input={value} />


                            {/* <IonList className="update_setting_page">
                                 {users.map((user, index) => {
                                   return (
                                       <IonItem key={index} lines="none" style={{ background: '#273685 !importan' }}>
                                           <div className="report_detail">
                                               <div className="report_status">
                                                   <IonAvatar className="employee_image">
                                                       <img src={user.image_url} alt="" />
                                                   </IonAvatar>
                                                   <IonLabel>
                                                       <div className="date_location_history">
                                                           <h4>{user.user.full_name}</h4>
                                                           <h5>{user.distance_in_meter} meter</h5>
                                                       </div>
                                                   </IonLabel>
                                               </div>
                                               <div className="attendance-status" style={{ textAlign: 'end' }}>
                                                   <h4>{user.status}</h4>
                                                   <h5>{user.attandance_time}</h5>
                                               </div>
                                           </div>
                                       </IonItem>
                                   );
                               })}
                             </IonList> */}
                        </div>
                    </div>
                </div>
                <IonLoading
                    cssClass="my-custom-class"
                    isOpen={loading}
                    onDidDismiss={() => setLoading(false)}
                    message={'Please wait...'}
                    duration={3500}
                />
            </IonContent>
        </IonPage>
    );
};

export default Employee_Attendance_Detail;
