import {
  IonButtons, IonContent, IonHeader, IonIcon,
  IonPage, IonToolbar, IonButton, IonLoading,
  IonItem, IonAlert, useIonViewWillEnter, IonToast,
} from "@ionic/react";
import { arrowBackOutline, locationOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { Camera, CameraResultType, CameraSource, CameraDirection } from "@capacitor/camera";
import { Geolocation } from "@capacitor/geolocation";
import { Plugins } from "@capacitor/core";
import { NativeSettings, AndroidSettings } from 'capacitor-native-settings';
import { useState } from "react";

import axios from "axios";
import { base_url } from "../App";
import "./Pictures.scss";
const { MyLocationSettingsPlugin } = Plugins;
const Pictures: React.FC = () => {
  const history = useHistory();
  const backClick = () => {
    history.goBack();
  };
  const { AppSettings } = Plugins;
  const alertImage =
    require("../assets/images/checkmark-circle-outline.png").default;
  var [image, setImages] = useState<any>("");
  const [coordinates, setCoordinates] = useState<any>({
    latitude: 0,
    longitude: 0,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [showLoading2, setShowLoading2] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [distanceInMeter, setDistanceInMeter] = useState<any>();
  const [locationEnabled, setLocationEnabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  useIonViewWillEnter(() => {
    async function takePicture() {
      // if (Capacitor.platform !== 'web') {
      const cameraResult = await Camera.getPhoto({
        quality: 5,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera,
        direction: CameraDirection.Front,
        promptLabelCancel: "Cancel",
        correctOrientation: false,
      })
        .then(async (cameraResult) => {
          let base64Image = "data:image/jpeg;base64," + cameraResult.base64String;
          setImages(base64Image);

        })
        .catch(function (error) {
          console.log(error);
        });
      setShowLoading2(true);
      await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }).then(coordinates => {
        setCoordinates(coordinates);
        if (coordinates.coords) {
          setDisabled(false);
        } else {
          console.log("error in getting coordinated..");
        }

        // getCompanyLocation
        axios
          .post(
            base_url + "/api/attendance/company-location",
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " +
                  JSON.parse(localStorage.getItem("user_data") as string).token
                    .token,
              },
            }
          )
          .then(function (response) {
            if (response.data.status == true) {
              var companyLocation = response.data.data;
              const earthRadius = 6371000;
              const latitude = coordinates.coords.latitude;
              const longitude = coordinates.coords.longitude;
              const latitudeFrom = companyLocation.latitude;
              const longitudeFrom = companyLocation.longitude;
              const dLat = deg2rad(latitudeFrom - latitude);
              const dLon = deg2rad(longitudeFrom - longitude);
              var a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(deg2rad(latitudeFrom)) *
                Math.cos(deg2rad(latitude)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
              var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
              var d = earthRadius * c;

              setDistanceInMeter(d.toFixed(2));
              setShowLoading2(false);
              console.log(d.toFixed(2), "location");
            }
          })
          .catch(function (error) {
            console.log(error);

          });
      }).catch((err) => {
        console.log(err, "open location")
        setIsOpen(true)
      });
    }
    takePicture();
  }, []);

  const deg2rad = (deg: any) => {
    return deg * (Math.PI / 180);
  };

  const takeAttendance = () => {
    setShowLoading(true);

    axios
      .post(
        base_url + "/api/attendance/save",
        {
          image: image,
          longitude: coordinates.coords.longitude,
          latitude: coordinates.coords.latitude,
          location: {
            longitude: coordinates.coords.longitude,
            latitude: coordinates.coords.latitude,
          },
          user_id: JSON.parse(localStorage.getItem("user_data") as string).user
            .id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_data") as string).token
                .token,
          },
        }
      )
      .then(function (response) {
        if (response.data.status == true) {
          setImages(null);
          setShowLoading(false);
          history.push("/history");
          setAlertMessage(response.data.message);
          // setShowAlert(true);
          setShowToast(true);
        } else {
          setShowLoading(false);
          setAlertMessage(response.data.message);
          history.push("/history");
          setShowToast(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const openAndroidSettings = async () => {
    try {
      await NativeSettings.openAndroid({
        option: AndroidSettings.ApplicationDetails,
      });
      console.log('Android settings opened successfully');
    } catch (error) {
      console.error('Error opening Android settings:', error);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="picture-toolbar">
          <div className="picture-main-div">
            <div className="back_btn">
              <IonButtons slot="start">
                <IonIcon icon={arrowBackOutline} onClick={backClick} />
                <h4>Picture</h4>
              </IonButtons>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="image-portion">
        {image ? (
          <div>
            <div className="image-show">
              <img className="img" src={image}></img>
            </div>
            <IonItem>
              <div className="component-wrapper">
              </div>
              <IonIcon icon={locationOutline} />
              <div className="location_detail">
                <span>Location(Accuracy:20meters)</span>
                <p>{distanceInMeter}meter</p>
              </div>
            </IonItem>
            <div className="Picture-button">
              <IonButton disabled={disabled} onClick={() => takeAttendance()}>
                Check In
              </IonButton>
            </div>
            <IonButton className="ion-margin" onClick={openAndroidSettings}>Open Location</IonButton>
            {/* <IonButton onClick={() => setIsOpen(true)}>alert</IonButton> */}
          </div>

        ) : (
          <div>
            <IonItem>
              <div className="component-wrapper">
              </div>
              <IonIcon icon={locationOutline} />
              <div className="location_detail">
                <span>Location(Accuracy:20meters)</span>
                <p>{distanceInMeter}meter</p>
              </div>
            </IonItem>
            <div className="Picture-button">
              <IonButton disabled={disabled} onClick={() => takeAttendance()}>
                Check In
              </IonButton>
            </div>
          </div>
        )}
        <IonToast
          isOpen={showToast}
          position="bottom"
          onDidDismiss={() => setShowToast(false)}
          color={"primary"}
          message={alertMessage}
          duration={2000}
        />
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
        <IonAlert
          isOpen={isOpen}
          header="Alert"
          mode="ios"
          subHeader="Please open your mobile location"
          // message="This is an alert!"
          buttons={['OK']}
          onDidDismiss={() => setIsOpen(false)}
        ></IonAlert>
      </IonContent>
    </IonPage >
  );
};

export default Pictures;
function showAlert(arg0: string, arg1: string) {
  throw new Error("Function not implemented.");
}

