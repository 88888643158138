import { IonApp, IonRouterOutlet, useIonViewWillEnter, IonRefresher, IonRefresherContent, IonContent, } from "@ionic/react";
import { RefresherEventDetail } from '@ionic/core';
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Login from "./components/Login";
import Home from "./pages/Home";
import MyAccount from "./pages/MyAccount";
import ChangePassword from "./pages/ChangePassword";
import Settings from "./pages/Settings";
import Report from "./pages/Report";
import Pictures from "./pages/Pictures";
import History from "./pages/History";
import Register from "./pages/Register";
import Account_Update from "./pages/Account_Update";
import Forgot_password from "./pages/Forgot_Password";
import Change_Profile_Picture from "./pages/Change_Profile_Picture";
import Add_Employee from "./pages/Add_Employee";
import Leave_Form from "./pages/Leave_Form";
import { useState } from "react";
import Employee_List from "./pages/Employee_List";
import UserTabList from "./pages/UserListTab";
import ProtectedRoute from "./ProtectedRoute";
import EmployeeLeaveHistoryDetail from "./pages/EmployeeLeaveHistoryDetail";
import ApplyLeaveForm from "./pages/ApplyLeaveForm";
import Stat from "./pages/Stat";
import Notifications from "./pages/Notifications";
import Employee_Manually_Attendance from "./pages/Employee_Manually_Attendance";
import Email_Check from "./pages/Email_Check";
import Reset_Password from "./pages/Reset_Password";
import Clock from "./pages/Clock";
// import Employee_Attendance_Filter from "./pages/Employee_Attendance_Detail";
import Employee_Attendance_Detail from "./pages/Employee_Attendance_Detail";
import Employee_Attendance_History from "./pages/Employee_Attendance_History";
import User_History_Detail from "./pages/User_History_Detail";
import CompanyLeaveApplication from "./pages/CompanyLeaveApplication";
import Checkout from "./pages/Checkout";
// import inputClock from "./components/inputAnalogClock";

const Swal = require('sweetalert2');


export let base_url = "https://attender.sumsols.com";

// export let base_url = "http://127.0.0.1:8000";

export let user_token = localStorage.user_data;
export let userData = localStorage.user_data;

export const sweetAlart = (message: any, icon: any) => {
  Swal.fire({
    position: 'center-center',
    icon: icon,
    title: message,
    showConfirmButton: false,
    timer: 2000
  });
}

const App: React.FC = () => {

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      // Any calls to load data go here
      event.detail.complete();
    }, 2000);
  }
  const [_token, setToken] = useState<any>(false);

  useIonViewWillEnter(() => {
    const t = localStorage.getItem("user_data");
    t && JSON.parse(t) ? setToken(true) : setToken(false);
  }, []);

  useIonViewWillEnter(() => {
    localStorage.setItem("_token", _token);
  }, [_token]);
  return (

    <IonApp>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
      </IonContent>

      <IonReactRouter>
        <Menu />
        <IonRouterOutlet id="main">
          <Route exact={true} path="/Register">
            <Register />
          </Route>
          <Route exact={true} path="/Forgot_password">
            <Forgot_password />
          </Route>
          <Route exact={true} path="/employee_attendance_detail">
            <Employee_Attendance_Detail />
          </Route>
          <Route exact={true} path="/employee_attendance_history">
            <Employee_Attendance_History />
          </Route>
          <Route exact={true} path="/user_history_detail">
            <User_History_Detail />
          </Route>

          <Route exact={true} path="/employee_manually_attendance">
            <Employee_Manually_Attendance />
          </Route>
          <ProtectedRoute exact path="/" component={Home} />
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/stat">
            <Stat />
          </Route>
          <Route path="/email_check">
            <Email_Check />
          </Route>

          <Route path="/reset_password">
            <Reset_Password />
          </Route>
          <Route path="/clock">
            <Clock />
          </Route>
          <Route path="/company-leave-application">
            <CompanyLeaveApplication />
          </Route>

          <ProtectedRoute exact path="/Settings" component={Settings} />
          <ProtectedRoute exact path="/Home" component={Home} />
          <ProtectedRoute exact path="/my-account" component={MyAccount} />
          <ProtectedRoute
            exact
            path="/change-password"
            component={ChangePassword}
          />
          <ProtectedRoute exact path="/reports" component={Report} />
          <ProtectedRoute exact path="/take-picture" component={Pictures} />
          <ProtectedRoute exact path="/checkout" component={Checkout} />
          <ProtectedRoute exact path="/history" component={History} />
          <ProtectedRoute
            exact
            path="/account_update"
            component={Account_Update}
          />
          <ProtectedRoute
            exact
            path="/notifications"
            component={Notifications}
          />
          <ProtectedRoute
            exact
            path="/change_profile_picture"
            component={Change_Profile_Picture}
          />
          <ProtectedRoute exact path="/Add_Employee" component={Add_Employee} />
          <ProtectedRoute
            exact
            path="/EmployeeLeaveHistoryDetail"
            component={EmployeeLeaveHistoryDetail}
          />
          <ProtectedRoute exact path="/leave_form" component={Leave_Form} />
          <ProtectedRoute
            exact
            path="/Employee_List"
            component={Employee_List}
          />
          <ProtectedRoute
            exact
            path="/apply-leave-form"
            component={ApplyLeaveForm}
          />



          <UserTabList />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
