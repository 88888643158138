import {
  IonButtons, IonContent, IonHeader, IonIcon,
  IonPage, IonImg, IonToolbar, IonAvatar,
  IonLoading,
  useIonViewWillEnter,
} from "@ionic/react";

import { arrowBackOutline, cameraOutline, homeOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import "./Change_Profile_Picture.scss";
import {
  CameraDirection,
  Camera, CameraResultType, CameraSource,
} from "@capacitor/camera";
import React, { useState } from "react";
import axios from "axios";
import { base_url } from "../App";
import PullToRefresh from "../components/PullToRefresh";

const Change_Profile_Picture: React.FC = () => {
  var [image, setImage] = useState<any>("");
  const [selectedImage, setSelectedImage] = useState<any>();
  const [showLoading, setShowLoading] = useState(false);
  const history = useHistory();

  const handleClick = () => {
    history.push("/Home");
  };
  const backButtonClick = () => {
    history.goBack();
  };

  useIonViewWillEnter(() => {
    setShowLoading(true);
    if (image !== "") {
      axios
        .post(
          base_url + "/api/attendance/update-profile-image",
          {
            image: image,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("user_data") as string).token
                  .token,
            },
          }
        )
        .then(function (response) {
          if (response.data.status == true) {
            var user_data = JSON.parse(
              localStorage.getItem("user_data") as string
            );
            user_data.user.image = response.data.user.image;
            user_data.user.image_url = response.data.user.image_url;
            localStorage.setItem('user_data', JSON.stringify(user_data));
            history.push("/home");
            setShowLoading(false);
          } else {
            alert(response.data.message);
            history.push("/change_profile_picture");
            setShowLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [image]);

  useIonViewWillEnter(() => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + "/api/account-setting-get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setSelectedImage(res.data.data.image_url);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const takePicture = async () => {
    const cameraResult = await Camera.getPhoto({
      quality: 20,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos,
    })
      .then(async (cameraResult) => {
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        let base64Image = "data:image/jpeg;base64," + cameraResult.base64String;
        setImage(base64Image);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleData = (userData: any) => {
    if (!userData) {
      profile_change();
    }
  }
  const profile_change = () => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + "/api/account-setting-get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setSelectedImage(res.data.data.image_url);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <IonPage>
      <IonContent fullscreen>
        <PullToRefresh userDataProps={handleData} />
        <IonHeader>
          <IonToolbar className="picture-toolbar">
            <div className="picture-main-div">
              <div className="back_btn">
                <IonButtons slot="start">
                  <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                  <h4>Update profile picture</h4>
                </IonButtons>
              </div>
              <div className="setting_icon">
                <IonIcon onClick={handleClick} icon={homeOutline} />
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <div className="profile_image_selection">
          <IonAvatar>
            <IonImg
              src={
                image
                  ? image
                  : selectedImage
                    ? selectedImage
                    : require("../assets/images/user.png")
              }
            ></IonImg>

            <IonIcon
              className="camera_icon"
              icon={cameraOutline}
              onClick={() => takePicture()}
            />
          </IonAvatar>
        </div>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
      </IonContent>
    </IonPage>
  );
};

export default Change_Profile_Picture;
