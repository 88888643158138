
import React, { useRef, useState } from 'react';
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  useIonViewWillEnter,
} from "@ionic/react";

import { RefresherEventDetail } from '@ionic/core';
import { arrowDownCircleOutline, arrowDownOutline, atCircle, atCircleSharp, chevronDownCircleOutline, chevronDownOutline, refreshCircleOutline } from "ionicons/icons";
import axios from 'axios';
import { base_url, user_token } from '../App';
import { useHistory } from 'react-router';


const PullToRefresh = ({ userDataProps }: any) => {

  const [userData, setUserData] = useState(false);

  const handleClick = () => {
    setUserData(userData);
    userDataProps(userData);
  };

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    event.detail.complete();
    setTimeout(() => {
      // Any calls to load data go here
      handleClick();
    }, 2000);
  }
  return (
  
      <IonRefresher
        slot="fixed"
        pullFactor={0.5} pullMin={100} pullMax={200}
        onIonRefresh={handleRefresh}>
        {/* <IonRefresherContent
          pullingIcon={atCircleSharp}
          pullingText="Pull to refresh"
          refreshingSpinner="crescent"
          refreshingText="Refreshing...">
        </IonRefresherContent> */}
        <IonRefresherContent
        pullingIcon={refreshCircleOutline}
        pullingText="Pull to refresh" 
        refreshingSpinner="crescent"
        refreshingText="Refreshing...">

        </IonRefresherContent>
      </IonRefresher>
  )
}

export default PullToRefresh