import { useHistory } from "react-router-dom";
import {
    IonButtons, IonContent, IonHeader, IonPage,
    IonToolbar, IonIcon, IonInput, IonItem,
    IonList, IonLabel, IonToast, IonLoading, useIonViewWillEnter
} from '@ionic/react';
import axios from "axios";
import React, { useRef, useState } from "react";
import "./ChangePassword.scss"
import { arrowBackOutline, eyeOutline, homeOutline } from "ionicons/icons";
import { base_url, user_token, sweetAlart } from "../App";
import PullToRefresh from "../components/PullToRefresh";

const ChangePassword: React.FC = () => {
    const Swal = require('sweetalert2')
    let token = JSON.parse(localStorage.getItem('user_data') as string).token;
    const [showToast2, setShowToast2] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const history = useHistory();
    const updatePassword = useRef(null);
    const confirm = useRef(null);
    const currentPassword = useRef(null)
    const [userPassword, setUserPassword] = useState<any>({
        currentPassword: "",
        password: "",
        confirmPassword: "",
    })
    const [passwordShown, setPasswordShown] = useState<any>({
        currentPassword: false,
        password: false,
        confirmPassword: false,
    });

    const togglePassword = (type: string) => {
        setPasswordShown({ ...passwordShown, [type]: !passwordShown[type] })
    };

    const homeClick = () => {
        history.push("/Home");
    };
    const backButtonClick = () => {
        history.push("/Home");
    };



    const handleClick = (e: any) => {
        e.preventDefault()
        setShowLoading(true);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.token
        }
        axios.post(base_url + '/api/update-password',
            {
                current_password: userPassword.currentPassword,
                password: userPassword.password,
                confirm_password: userPassword.confirmPassword,
            },
            {
                headers: headers,
            })
            .then(function (response) {
                if (response.data.status == true) {
                    setShowLoading(false);
                    setUserPassword("");
                    history.push("/change-password");
                    // setAlertMessage(response.data.message);
                    // setShowToast2(true);
                    sweetAlart(response.data.message, 'success');
                } else {
                    sweetAlart(response.data.message, 'warning');
                    setShowLoading(false);
                }
            })
            .catch(function (error) {
                setShowLoading(false);
                console.log(error);
            });
    };

    // const handleData = (userData: any) => {
    //     if (!userData) {
    //         change_password();
    //     }
    // }
    // const change_password = () => {
    //     setShowLoading(true);
    //     setShowLoading(true);
    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + token.token
    //     }
    //     axios.post(base_url + '/api/update-password',
    //         {
    //             current_password: passwordShown.currentPassword,
    //             password: passwordShown.password,
    //             confirm_password: passwordShown.confirmPassword,
    //         },
    //         {
    //             headers: headers,
    //         })
    //         .then(function (response) {
    //             if (response.data.status == true) {
    //                 setShowLoading(false);
    //                 setUserPassword("");

    //                 history.push("/change-password");
    //                 // setAlertMessage(response.data.message);
    //                 // setShowToast2(true);
    //                 sweetAlart(response.data.message, 'success');
    //             } else {
    //                 sweetAlart(response.data.message, 'warning');
    //                 setShowLoading(false);
    //             }
    //         })
    //         .catch(function (error) {
    //             setShowLoading(false);
    //             console.log(error);
    //         });
    // }

    return (
        <IonPage>
            <IonContent fullscreen className="all_input">
                {/* <PullToRefresh userDataProps={handleData} /> */}

                <IonHeader>
                    <IonToolbar className="change-password">
                        <div className="settings-main-div">
                            <div className="back_btn">
                                <IonButtons slot="start">
                                    <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                                    <h4>Change password</h4>
                                </IonButtons>
                            </div>
                            <div className="home_icon">
                                <IonIcon onClick={homeClick} icon={homeOutline} />
                            </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
                <IonList>
                    <h3>Create new password for your account</h3>
                    <IonItem className="ions_input">
                        <div className="item_portion">
                            <div className="input_field">
                                <IonLabel position="stacked" className="label">Current Password</IonLabel>
                                <IonInput placeholder="Current Password"
                                    type={passwordShown.currentPassword ? "text" : "password"}
                                    value={userPassword.currentPassword}
                                    ref={currentPassword}
                                    onIonChange={(e) => {
                                        let my_password = userPassword;
                                        my_password.currentPassword = e.detail.value;
                                        setUserPassword(my_password)
                                    }}></IonInput>
                            </div>
                            <div className="eye_icon">
                                <IonIcon onClick={() => togglePassword('currentPassword')} icon={eyeOutline}> </IonIcon>
                            </div>
                        </div>
                    </IonItem>
                    <IonItem className="ions_input">
                        <div className="item_portion">
                            <div className="input_field">
                                <IonLabel position="stacked" className="label">New Password</IonLabel>
                                <IonInput placeholder="New Password"
                                    type={passwordShown.password ? "text" : "password"}
                                    value={userPassword.password}
                                    ref={updatePassword}
                                    onIonChange={(e) => {
                                        let my_password = userPassword;
                                        my_password.password = e.detail.value;
                                        setUserPassword(my_password);

                                    }}></IonInput>
                            </div>
                            <div className="eye_icon">
                                <IonIcon onClick={() => togglePassword('password')} icon={eyeOutline}> </IonIcon>
                            </div>
                        </div>
                    </IonItem>
                    <IonItem className="ions_input">
                        <div className="item_portion">
                            <div className="input_field">
                                <IonLabel position="stacked" className="label">Confirm Password</IonLabel>
                                <IonInput placeholder="Confirm Password"
                                    type={passwordShown.confirmPassword ? "text" : "password"}
                                    value={userPassword.confirmPassword}
                                    ref={confirm}
                                    onIonChange={(e) => {
                                        let my_password = userPassword;
                                        my_password.confirmPassword = e.detail.value;
                                        setUserPassword(my_password)
                                    }}></IonInput>
                            </div>
                            <div className="eye_icon">
                                <IonIcon onClick={() => togglePassword('confirmPassword')} icon={eyeOutline}> </IonIcon>
                            </div>
                        </div>
                    </IonItem>
                    <IonButtons slot="start" onClick={(e) => handleClick(e)}>
                        <button type="button" className="save-password">
                            Save Password
                            <IonLoading
                                cssClass='my-custom-class'
                                isOpen={showLoading}
                                onDidDismiss={() => setShowLoading(false)}
                                message={'Please wait...'}
                            />
                            {/* <IonToast
                                isOpen={showToast2}
                                onDidDismiss={() => setShowToast2(false)}
                                color={"primary"}
                                duration={2000}
                                message={alertMessage}
                            /> */}
                        </button>
                    </IonButtons>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default ChangePassword;
