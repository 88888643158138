import {
  IonButtons, IonContent, IonHeader, IonIcon,
  IonList, IonPage, IonItem, IonLabel,
  IonToolbar, IonLoading, IonSelect, IonSelectOption,
  IonToast, IonTextarea, useIonViewWillEnter,
} from "@ionic/react";
import {
  homeOutline, arrowBackOutline, chevronDownOutline,
} from "ionicons/icons";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./ApplyLeaveForm.scss";
import { base_url } from "../App";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PullToRefresh from "../components/PullToRefresh";
const ApplyLeaveForm: React.FC = () => {
  const onChange = (dates: [any, any]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const [userHistory, setuserHistory] = useState([
    {
      name: "",
      key: "",
      id: "",
    },
  ]);

  const [showLoading, setShowLoading] = useState(true);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState(null);
  const history = useHistory();
  const [date, setDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const [leave_start_date, setLeave_start_date] = useState<string>();
  const [leave_type, setLeaveType] = useState<string>();
  const [text, setText] = useState("");
  const [showToast2, setShowToast2] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [users, setUser] = useState([
    {
      distance_in_meter: "",
      attandance_time: "",
      created_at: "",
      deleted_at: "",
      user_id: "",
      image_url: "",
      status: "",
      phone_no: "",
      updated_at: "",
      role: { name: "" },
      email: "",
      formatted_datetime: "",
      user: { full_name: "" },
    },
  ]);

  const backClick = () => {
    history.goBack();
  };
  let token = JSON.parse(localStorage.getItem("user_data") as string).token;
  const homeClick = () => {
    history.push('/Home')
  }

  const handleClick = (e: any) => {
    e.preventDefault();
    setShowLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.token,
    };
    axios
      .post(
        base_url + "/api/leave/save",
        {
          leave_start_date: startDate,
          leave_ends_date: endDate,
          key: leave_type,
          Reason: text,
          status: "Pending",
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.data.status == true) {
          setShowLoading(true);
          setLeave_start_date("");
          setAlertMessage(response.data.message);
          setShowLoading(false);
          setShowToast2(true);
          history.push("/home");
        }
      })
      .catch(function (error) {
        setShowLoading(false);
        console.log(error);
      });
  };
  useIonViewWillEnter(() => {
    setLoading(true);
    let currentDate = `${date.getFullYear()}-${date.getMonth() + 1
      }-${date.getDate()}`;
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + "/api/attendance/companyhistory",
        { date: currentDate },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setUser(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);
  useIonViewWillEnter(() => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;

    axios
      .post(
        base_url + "/api/leave-type/show",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setuserHistory(res.data.data);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleData = (userData: any) => {
    if (!userData) {
      update_account();
    }
  }
  const update_account = () => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;

    axios
      .post(
        base_url + "/api/leave-type/show",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setuserHistory(res.data.data);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  return (
    <IonPage className="wrapper-1">

      <IonContent fullscreen className="all_input">

        <PullToRefresh userDataProps={handleData} />
        <IonHeader className="ion-no-border p-0">
          <IonToolbar className="report-toolbar">
            <div className="report-main-div">
              <div className="back_btn">
                <IonButtons slot="start">
                  <IonIcon icon={arrowBackOutline} onClick={backClick} />
                  <h4>Leave Form</h4>
                </IonButtons>
              </div>
              <div className="home_icon">
                <IonIcon onClick={homeClick} icon={homeOutline} />
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <div className="wrapper-nawa">
          <div className="content-main-home calender-plugin history p-0">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <div>
              <IonList className="update_setting_page">
                <IonItem className="ions_input">
                  <IonLabel position="stacked" className="label">
                    Leave Type
                  </IonLabel>
                  <IonSelect
                    className="select-icon"
                    placeholder="Type of leave"
                    onIonChange={(e) => setLeaveType(e.detail.value!)}
                  >
                    {userHistory.map((user, index) => {
                      return (
                        <div key={index}>
                          <IonSelectOption value={user.key}>
                            {user.name}
                          </IonSelectOption>
                        </div>
                      );
                    })}
                  </IonSelect>
                  <IonIcon
                    className="chevronDownOutline"
                    icon={chevronDownOutline}
                  />
                </IonItem>

                <IonItem className="ions_input">
                  <IonTextarea
                    className="Text-area"
                    onIonChange={(e) => setText(e.detail.value!)}
                    placeholder="Enter leave information here..."
                  ></IonTextarea>
                </IonItem>
                <IonButtons slot="start" onClick={(e) => handleClick(e)}>
                  <button type="button" className="apply-btn">
                    Apply for leave
                    <IonLoading
                      cssClass="my-custom-class"
                      isOpen={showLoading}
                      onDidDismiss={() => setShowLoading(false)}
                      message={"Please wait..."}
                    />
                    <IonToast
                      isOpen={showToast2}
                      onDidDismiss={() => setShowToast2(false)}
                      color={"primary"}
                      position="middle"
                      duration={2000}
                      message={alertMessage}
                    />
                  </button>
                </IonButtons>
              </IonList>
            </div>
          </div>
        </div>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={"Please wait..."}
          duration={3500}
        />
      </IonContent>
    </IonPage>
  );
};

export default ApplyLeaveForm;
