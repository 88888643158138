import { useHistory } from "react-router-dom";
import {
  IonButtons,
  IonLoading,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonLabel,
  IonToast,
  IonDatetime,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
// import { GoogleMap } from "@capacitor/google-maps";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { Geolocation } from "@capacitor/geolocation";
import { useEffect, useRef } from "react";
import "./Register.scss";
import { arrowBackOutline, eyeOutline } from "ionicons/icons";
import React, { useState } from "react";
import { base_url, user_token, sweetAlart } from "../App";
import { exit, exitCode } from "process";
import PullToRefresh from "../components/PullToRefresh";

interface coords {
  latitude: number;
  longitude: number;
}
const Register: React.FC = () => {

  let token = JSON.parse(localStorage.getItem("user_data") as string).token;
  const userData: any = JSON.parse(
    localStorage.getItem("user_data") as string
  ).user;
  const history = useHistory();
  const [companyDetail, setCompanyDetail] = useState<any>({
    first_name: "",
    last_name: "",
    phone_no: "",
    email: "",
    password: "",
    confirm_password: "",
    companyName: "",
    address: "",
  })
  const [showToast2, setShowToast2] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);
  const [shift_Starts_At, setShift_Starts_At] = useState<string>(userData.shift_starts_at);
  const [shift_Ends_At, setShift_Ends_At] = useState<string>(userData.shift_ends_at);
  const [coords, setCoords] = useState<coords>({
    latitude: 0,
    longitude: 0,
  });

  // const togglePassword = () => {
  //   setPasswordShown(!passwordShown);
  // };
  const getCurrentPosition = () => {
    Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    })
      .then((res) => {
        setCoords((): coords => {
          return res.coords
        });
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  const apiKey = "AIzaSyBCafTKnC7aHB6eB32KXrmtoFGPJ1mq9Go";
  useIonViewWillEnter(() => {
    getCurrentPosition()
  }, []);

  let center = {
    lat: coords.latitude,
    lng: coords.longitude,
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: coords.latitude,
      lng: coords.longitude,
    });
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const [passwordShown, setPasswordShown] = useState<any>({
    password: false,
    confirmPassword: false,
  });
  const togglePassword = (type: string) => {
    setPasswordShown({ ...passwordShown, [type]: !passwordShown[type] })
  };


  const handleClick = () => {
    setShowLoading(true);

    axios
      .post(base_url + "/api/auth/register", {
        first_name: companyDetail.firstName,
        last_name: companyDetail.lastName,
        phone_no: companyDetail.phone_no,
        email: companyDetail.email,
        password: companyDetail.password,
        confirm_password: companyDetail.confirm_password,
        companyName: companyDetail.companyName,
        address: companyDetail.address,
        shift_Starts_At,
        shift_Ends_At,
        role_key: "company",
        ...coords,
      })
      .then(function (response) {
        if (response.data.status == true) {
          setCompanyDetail({
            first_name: "",
            last_name: "",
            phone_no: "",
            email: "",
            password: "",
            confirm_password: "",
            companyName: "",
            address: "",
          })
          setShowLoading(false);
          history.push("/login");
          sweetAlart(response.data.message, 'success');
        } else {
          // setAlertMessage(response.data.message);
          // setShowToast2(true);
          // setShowLoading(false);
          sweetAlart(response.data.message, 'warning');
          setShowLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setShowLoading(false);
      });
  };
  const backClick = () => {
    history.goBack();
  };

  const handleData = (userData: any) => {
    if (!userData) {
      register();
    }
  }
  const register = () => {
    console.log("true value ");
  }

  return (
    <IonPage>

      <IonContent fullscreen className="all_input">
        <PullToRefresh userDataProps={handleData} />
        <IonHeader>
          <IonToolbar className="register_toolbar">
            <div className="register_header">
              <div className="back_btn">
                <IonButtons slot="start">
                  <IonIcon icon={arrowBackOutline} onClick={backClick} />
                  <h4>Register</h4>
                </IonButtons>
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <h3>Welcome and register here </h3>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              First Name
            </IonLabel>
            <IonInput
              placeholder="First Name"
              type="text"
              value={companyDetail.firstName}
              onIonChange={(e) => {
                let comp_name = companyDetail;
                comp_name.firstName = e.detail.value;
                setCompanyDetail(comp_name)
              }}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Last Name
            </IonLabel>
            <IonInput
              placeholder="Last Name"
              type="text"
              value={companyDetail.lastName}
              onIonChange={(e) => {
                let comp_name = companyDetail;
                comp_name.lastName = e.detail.value;
                setCompanyDetail(comp_name)
              }}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Phone Number
            </IonLabel>
            <IonInput
              placeholder="Phone Number"
              type="tel"
              value={companyDetail.phone_no}
              onIonChange={(e) => {
                let comp_name = companyDetail;
                comp_name.phone_no = e.detail.value;
                setCompanyDetail(comp_name)
              }}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Email Address
            </IonLabel>
            <IonInput
              placeholder="Email Address"
              type="email"
              value={companyDetail.email}
              onIonChange={(e) => {
                let comp_name = companyDetail
                comp_name.email = e.detail.value;
                setCompanyDetail(comp_name)
              }}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <div className="item_portion">
              <div className="input_field">
                <IonLabel position="stacked" className="label">
                  Password
                </IonLabel>
                <IonInput
                  placeholder="Current Password"
                  type={passwordShown.password ? "text" : "password"}
                  value={companyDetail.password}
                  onIonChange={(e) => {
                    let comp_name = companyDetail;
                    comp_name.password = e.detail.value;
                    setCompanyDetail(comp_name)
                  }}
                ></IonInput>
              </div>
              <div className="eye_icon">
                <IonIcon onClick={() => togglePassword('password')} icon={eyeOutline}>
                  {" "}
                </IonIcon>
              </div>
            </div>
          </IonItem>
          <IonItem className="ions_input">
            <div className="item_portion">
              <div className="input_field">
                <IonLabel position="stacked" className="label">
                  Confirm Password
                </IonLabel>
                <IonInput
                  placeholder="Confirm Password"
                  type={passwordShown.confirmPassword ? "text" : "password"}
                  value={companyDetail.confirm_password}
                  onIonChange={(e) => {
                    let comp_name = companyDetail;
                    comp_name.confirm_password = e.detail.value;
                    setCompanyDetail(comp_name)
                  }}
                ></IonInput>
              </div>
              <div className="eye_icon">
                <IonIcon onClick={() => togglePassword('confirmPassword')} icon={eyeOutline}>
                  {" "}
                </IonIcon>
              </div>
            </div>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Company Name
            </IonLabel>
            <IonInput
              placeholder="Company Name"
              type="text"
              value={companyDetail.companyName}
              onIonChange={(e) => {
                let comp_name = companyDetail;
                comp_name.companyName = e.detail.value;
                setCompanyDetail(comp_name)
              }}
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Shift starting
            </IonLabel>
            <IonDatetime
              placeholder={shift_Starts_At ? "" : "Shift starting"}
              displayFormat="h:mm:a"
              value={shift_Starts_At}
              onIonChange={(e) => setShift_Starts_At(e.detail.value!)}
            ></IonDatetime>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Shift ending
            </IonLabel>
            <IonDatetime
              displayFormat="h:mm:a"
              placeholder="Shift ending"
              value={shift_Ends_At}
              onIonChange={(e) => setShift_Ends_At(e.detail.value!)}
            ></IonDatetime>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Address
            </IonLabel>
            <IonInput
              placeholder="Address"
              type="text"
              value={companyDetail.address}
              onIonChange={(e) => {
                let comp_name = companyDetail;
                comp_name.address = e.detail.value;
                setCompanyDetail(comp_name)
              }}
            ></IonInput>
          </IonItem>
          {/* <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Latitude
            </IonLabel>
            <IonInput
              value={coords.latitude == 0? " ":JSON.stringify(coords.latitude)}
              placeholder="Latitude"
              type="text"
              onIonChange={(e) =>
                setCoords((): coords => {
                  return {
                    longitude: coords.longitude,
                    latitude: parseFloat(e.detail.value!),
                  };
                })
              }
            ></IonInput>
          </IonItem>
          <IonItem className="ions_input">
            <IonLabel position="stacked" className="label">
              Longitude
            </IonLabel>
            <IonInput
              value={coords.longitude == 0? " ":JSON.stringify(coords.longitude)}
              placeholder="Longitude"
              type="text"
              onIonChange={(e) =>
                setCoords((): coords => {
                  return {
                    latitude: coords.latitude,
                    longitude: parseFloat(e.detail.value!),
                  };
                })
              }
            ></IonInput>
          </IonItem> */}
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "400px",
              }}
              center={center}
              zoom={5}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={(e) => {
                setCoords({
                  latitude: e.latLng?.lat() ? e.latLng?.lat() : coords.latitude,
                  longitude: e.latLng?.lng() ? e.latLng?.lng() : coords.longitude
                })
              }}

            >
              {/* Child components, such as markers, info windows, etc. */}
              <Marker
                visible
                position={{ lat: coords.latitude, lng: coords.longitude }}
                draggable
                onDrag={(e) => {
                  setCoords({
                    latitude: e.latLng?.lat() ? e.latLng?.lat() : coords.latitude,
                    longitude: e.latLng?.lng() ? e.latLng?.lng() : coords.longitude
                  })
                }}

              />

              <></>
            </GoogleMap>
          ) : (
            <></>
          )}
          {/* <IonItem className="ions_input w_280px">
            <capacitor-google-map
              ref={mapRef}
              style={{
                display: "inline-block",
                width: 275,
                height: 400,
              }}
            ></capacitor-google-map>
          </IonItem> */}
          <IonButtons slot="start" onClick={(e) => handleClick()}>
            <button type="button" className="sign_up">
              Register
            </button>
            <IonLoading
              cssClass="my-custom-class"
              isOpen={showLoading}
              message={"Please wait..."}
            />
            <IonToast
              isOpen={showToast2}
              onDidDismiss={() => setShowToast2(false)}
              color={"primary"}
              position="middle"
              duration={2000}
              message={alertMessage}
            />
          </IonButtons>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Register;
