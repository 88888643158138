import {
  IonButtons,IonHeader,IonIcon,IonProgressBar,
  IonToolbar,useIonViewWillEnter
} from "@ionic/react";
import axios from "axios";
import { base_url } from "../App";
import { useState } from "react";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import {
  arrowBackOutline,homeOutline,
} from "ionicons/icons";

const LeaveHistoryHeader = () => {
  const history = useHistory();
  const handleClick = () => {
    history.goBack();
  };
  const homeClick = () => {
    history.push("/Home");
  };
  const [showLoading, setShowLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(
    {
      full_name: "",
    },
  );
  const [leaveCounts, setleaveCount] = useState<any>("");
  const [totalLeaves, setTotalLeaves] = useState<any>("");
  const [greetingMsg, setGreetingMsg] = useState<any>("");
  const cookies = new Cookies();
  var idd = cookies.get("id");

  useIonViewWillEnter(() => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + `/api/leave/show`,
        {
          id: idd,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setUserInfo(res.data.user_info);
        setleaveCount(res.data.leaves_count);
        setGreetingMsg(res.data.greeting_msg);
        setShowLoading(false);
        var approved_leaves = res.data.approved_leaves;
        var sum = 0;
        approved_leaves.forEach(function (value: any) {
          sum = sum + value.total_leaves;
        });
        setTotalLeaves(sum);

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <IonHeader className="ion-no-border p-0">
      <IonToolbar className="report-toolbar">
        <div className="report-main-div">
          <div className="back_btn">
            <IonButtons slot="start">
              <IonIcon icon={arrowBackOutline} onClick={handleClick} />
              <h4>Leave history</h4>
            </IonButtons>
          </div>
          <div className="home_icon">
            <IonIcon onClick={homeClick} icon={homeOutline} />
          </div>
        </div>
        <div className="leave_status_bar">
          <h5>{greetingMsg}, {userInfo.full_name}</h5>
          <div>
            <div className="no_of_leaves">
              <h6>{totalLeaves} Leaves</h6>
              <h6>12 Leaves</h6>
            </div>
            <IonProgressBar className="leave_bar" value={(totalLeaves) / 12} />
          </div>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};
export default LeaveHistoryHeader;
