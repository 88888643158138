import {
  IonButtons, IonContent, IonHeader, IonIcon,
  IonMenuButton, IonPage, IonToolbar, IonButton,
  IonLoading,
  useIonViewWillEnter,
  IonRefresher,
  IonRefresherContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";
// import { AppVersion } from "@ionic-native/app-version";
import {
  checkmarkCircleOutline, documentOutline, homeOutline, newspaperOutline,
  personCircleOutline, peopleCircleOutline, listOutline,
  exitOutline,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { base_url } from "../App";
import axios from "axios";
import React from "react";
import Cookies from "universal-cookie";
import { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Capacitor } from "@capacitor/core";
import { Plugins } from "@capacitor/core";
import "./Home.scss";
import "react-datepicker/dist/react-datepicker.css";
import PullToRefresh from "../components/PullToRefresh";
import { AppVersion } from '@awesome-cordova-plugins/app-version';

const Home: React.FC = () => {

  const history = useHistory();
  const current = new Date();
  const present = 0.80;
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const newDate = `${current.getDate()} ${monthNames[current.getMonth()]}, ${current.getFullYear()}`;
  const [cDate, setCDate] = useState(newDate);
  const [showLoading, setShowLoading] = useState(true);
  const [checkedIn, setCheckedIn] = useState(false)
  const [userAttendance, setuserAttendance] = useState<any>({
    present_days: "",
    present_percentage: "",
    leave_days: "",
    leave_percentage: "",
    absent_days: "",
    absent_percentage: "",
  });
  const [userDetail, setuserDetail] = useState({
    first_name: "",
    phone_no: "",
    last_name: "",
    email: "",
    id: "",
    shift_starts_at: "",
    shift_ends_at: "",
  });

  /** Check either user is checked in or not. */


  useIonViewWillEnter(() => {
    if (Capacitor.isNative) {
      console.log(AppVersion.getVersionNumber, AppVersion.getVersionCode, AppVersion.getAppName, AppVersion.getPackageName);
      Plugins.App.addListener("backButton", (e: any) => {
        if (window.location.pathname === "/") {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Are you sure");
          if (ans) {
            Plugins.App.exitApp();
          }
        } else if (window.location.pathname === "/login") {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Are you sure");
          if (ans) {
            Plugins.App.exitApp();
          }
        }
      });
    }
  }, []);

  useIonViewWillEnter(() => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + "/api/account-setting-get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res: any) => {
        setuserDetail(res.data.data);
        setuserAttendance(res.data.attendance);
        setShowLoading(false);
        setCheckedIn(!checkedIn)
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);


  const key = JSON.parse(localStorage.getItem("user_data") as string)
    ? JSON.parse(localStorage.getItem("user_data") as string).user.role.key
    : null;
  const handleClick = () => {
    history.push("/reports");
  };
  const leaveClick = () => {
    history.push("/apply-leave-form");
  };
  const accountClick = (id: any) => {
    history.push("/my-account", id);
    const cookies = new Cookies();
    cookies.set('id', id, { path: '/' });
  };
  const employeeHistory = () => {
    history.push("/history");
  };
  const checkIn = () => {
    history.push("/take-picture");
  };
  const checkOut = () => {
    history.push("/checkout");
  };
  const addEmployee = () => {
    history.push("/Add_Employee");
  };
  const employeeList = () => {
    history.push("/Employee_List");
  };
  const userSearchHistory = () => {
    history.push("/employee_attendance_detail");
  };

  const userHistoryDetail = () => {
    history.push("/user_history_detail");
  }
  useIonViewWillEnter(() => {
    setInterval(() => {
      setCDate(newDate);
    }, 1000);
  });

  // function handleRefresh(event: CustomEvent<RefresherEventDetail>) {

  //   setTimeout(() => {
  //     // Any calls to load data go here
  //     event.detail.complete();

  //   }, 5000);
  // }

  const handleData = (userData: any) => {
    if (!userData) {
      home_func();
    }
  }
  const home_func = () => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + "/api/account-setting-get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res: any) => {
        setuserDetail(res.data.data);
        setuserAttendance(res.data.attendance);
        setShowLoading(false);
        setCheckedIn(!checkedIn)
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  return (
    <IonPage className="wrapper-1">
      <IonContent fullscreen>
        <PullToRefresh userDataProps={handleData} />
        {/* <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher> */}


        <IonHeader className="ion-no-border">
          <IonToolbar>
            <div className="top-header">
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <h4>Home</h4>
              <div>
                <IonIcon icon={homeOutline} />
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <div className="wrapper-nawa">
          <div className="current-date">
            {/* <span>{monthName} {date}, {year}</span> */}
            <span>{cDate}</span>
          </div>
          <div className="attendance-details">
            {/* <p className="attender-title">Attendance</p> */}
            {key && key === "user" ? (
              <p className="attender-title">you are logged in as employee</p>
            ) : (
              ""
            )}
            {key && key === "company" ? (
              <p className="attender-title">you are logged in as company</p>
            ) : (
              ""
            )}
            <div className="details-inner">

              <div style={{ width: 80 }}>
                <CircularProgressbar
                  value={userAttendance.present_days}
                  text={`${userAttendance.present_percentage}%`}
                  styles={buildStyles({
                    textColor: "#273686",
                    pathColor: "#273686",
                  })}
                />
                <p className="circle-text">Present</p>
              </div>
              <div style={{ width: 80 }}>
                <CircularProgressbar
                  value={userAttendance.leave_days}
                  text={`${userAttendance.leave_percentage}%`}
                  styles={buildStyles({
                    textColor: "#5F8575",
                    pathColor: "#5F8575",
                  })}
                />
                <p className="circle-text">Leave</p>
              </div>
              <div style={{ width: 80 }}>
                <CircularProgressbar
                  value={userAttendance.absent_days}
                  text={`${userAttendance.absent_percentage}%`}
                  styles={buildStyles({
                    textColor: "#008000",
                    pathColor: "#008000",
                  })}
                />
                <p className="circle-text">Absent</p>
              </div>


              {/* <div className="attended-or-skipped">
                <h1>Attended</h1>
                <h2>Skipped</h2>
              </div> */}
            </div>
            {/* <span>
              Now worries...you're <b>SAFE</b> and on track
            </span> */}
          </div>
          <div className="content-main-home">
            {/* {key && key === "company" ? (
              <div className="pages">
                <IonButton
                  onClick={() => {
                    addEmployee();
                  }}
                >
                  <div>
                    <IonIcon icon={peopleCircleOutline} />
                    <p>Add Employee</p>
                  </div>
                </IonButton>
                <IonButton
                  className="person"
                  onClick={() => {
                    employeeList();
                  }}
                >
                  <div>
                    <IonIcon icon={listOutline} />
                    <p>Employee List</p>
                  </div>
                </IonButton>
              </div>
            ) : (
              ""
            )} */}

            {/* Check Out Here */}
            {/* {
              checkedIn ? 
            <div className="pages">
            <IonButton
              id="checkout"
              onClick={() => {
                checkIn();
              }}
            >
              <div>
                <IonIcon icon={checkmarkCircleOutline} />
                <p>Check Out</p>
              </div>
            </IonButton>
            <IonButton
              className="person"
              onClick={() => {
                accountClick(userDetail.id);
              }}
            >
              <div>
                <IonIcon icon={personCircleOutline} />
                <p>My Account</p>
              </div>
            </IonButton>
          </div>
              
              :  */}

            {/* common button checkin and my account start*/}
            {/* <div className="pages">
              <IonButton
                id="checkedIn"
                onClick={() => {
                  checkIn();
                }}
              >
                <div>
                  <IonIcon icon={checkmarkCircleOutline} />
                  <p>Check In</p>
                </div>
              </IonButton>
              <IonButton
                className="person"
                onClick={() => {
                  accountClick(userDetail.id);
                }}
              >
                <div>
                  <IonIcon icon={personCircleOutline} />
                  <p>My Account</p>
                </div>
              </IonButton>
            </div> */}
            {/* common button checkin and my account end*/}

            {/* } */}

            {key && key === "user" ? (
              <div className="pages">
                <IonGrid className="ion-no-padding">
                  <IonRow>
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list"
                        id="checkedIn"
                        onClick={() => {
                          checkIn();
                        }}
                      >
                        <div>
                          <IonIcon icon={checkmarkCircleOutline} />
                          <p>Check In</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list"
                        id="checkedIn"
                        onClick={() => {
                          checkOut();
                        }}
                      >
                        <div>
                          <IonIcon icon={exitOutline} />
                          <p>Check Out</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div
                        className="person btns-list"
                        onClick={() => {
                          accountClick(userDetail.id);
                        }}
                      >
                        <div>
                          <IonIcon icon={personCircleOutline} />
                          <p>My Account</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list"
                        onClick={() => {
                          employeeHistory();
                        }}
                      >
                        <div>
                          <IonIcon icon={newspaperOutline} />
                          <p>History</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list person"

                        onClick={() => {
                          leaveClick();
                        }} >
                        <div>
                          <IonIcon icon={documentOutline} />
                          <p>Apply leave</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list"
                        // id="checkedIn"
                        onClick={() => {
                          userHistoryDetail();
                        }}
                      >
                        <div>
                          <IonIcon icon={newspaperOutline} />
                          <p>Detail</p>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            ) : (
              ""
            )}
            {key && key === "company" ? (
              <div className="pages">
                <IonGrid className="ion-no-padding">
                  <IonRow>
                    {/* <IonCol size="3" className="col-setting">
                      <div className="btns-list"
                        onClick={() => {
                          addEmployee();
                        }}
                      >
                        <div>
                          <IonIcon icon={peopleCircleOutline} />
                          <p>Add Employee</p>
                        </div>
                      </div>
                    </IonCol> */}
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list person"
                        onClick={() => {
                          employeeList();
                        }}
                      >
                        <div>
                          <IonIcon icon={listOutline} />
                          <p>Employee List</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list"
                        id="checkedIn"
                        onClick={() => {
                          checkIn();
                        }}
                      >
                        <div>
                          <IonIcon icon={checkmarkCircleOutline} />
                          <p>Check In</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list"
                        id="checkedIn"
                        onClick={() => {
                          checkOut();
                        }}
                      >
                        <div>
                          <IonIcon icon={exitOutline} />
                          <p>Check Out</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div
                        className="person btns-list"
                        onClick={() => {
                          accountClick(userDetail.id);
                        }}
                      >
                        <div>
                          <IonIcon icon={personCircleOutline} />
                          <p>My Account</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list"
                        onClick={() => {
                          employeeHistory();
                        }}
                      >
                        <div>
                          <IonIcon icon={newspaperOutline} />
                          <p>History</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list person"
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        <div>
                          <IonIcon icon={documentOutline} />
                          <p>Report</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list"
                        // id="checkedIn"
                        onClick={() => {
                          userHistoryDetail();
                        }}
                      >
                        <div>
                          <IonIcon icon={newspaperOutline} />
                          <p>Detail</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="3" className="col-setting">
                      <div className="btns-list">
                        <div>
                          <IonIcon icon={documentOutline} />
                          <p>Leave</p>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            ) : (
              ""
            )}
            <IonList lines="none">
              <IonItem className="">
                <div className="attender-list">
                  <p className="ion-no-margin">Lorem ipsum dolor sit amet consectetur </p>
                  <IonLabel>10:00pm</IonLabel>
                </div>
              </IonItem>
            </IonList>

            {/* {key && key === "company" ? (
              <div className="pages">
                <IonButton
                  // id="checkedIn"
                  onClick={() => {
                    userSearchHistory();
                  }}
                >
                  <div>
                    <IonIcon icon={newspaperOutline} />
                    <p>Detail History</p>
                  </div>
                </IonButton>
              </div>
            ) : (
              ""
            )} */}

            {/* {key && key === "user" ? (
              <div className="pages">
                <IonButton
                  // id="checkedIn"
                  onClick={() => {
                    userHistoryDetail();
                  }}
                >
                  <div>
                    <IonIcon icon={newspaperOutline} />
                    <p>Detail</p>
                  </div>
                </IonButton>
              </div>
            ) : (
              ""
            )} */}
          </div>


        </div>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
          duration={2000}
        />
      </IonContent>
    </IonPage >
  );
};

export default Home;
