import {
  IonContent,IonIcon,IonImg,IonItem,
  IonLabel,IonList,IonMenu,IonMenuToggle,
  IonAvatar,IonLoading,IonText, useIonViewWillEnter
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
  calendarClearOutline,logOutOutline,personCircleOutline,personOutline,
  settingsOutline,newspaperOutline,notificationsOutline,handRightOutline, cameraOutline, image,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import "./Menu.scss";
import React, { useState } from "react";
import axios from "axios";
import { base_url } from "../App";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const companyAppPages: AppPage[] = [
  // {
  //   title: "My account",
  //   url: "/my-account",
  //   iosIcon: personOutline,
  //   mdIcon: personCircleOutline,
  // },
  {
    title: "Change Password",
    url: "/change-password",
    iosIcon: calendarClearOutline,
    mdIcon: calendarClearOutline,
  },
  {
    title: "Notifications",
    url: "/notifications",
    iosIcon: notificationsOutline,
    mdIcon: notificationsOutline,
  },
  {
    title: "Manual Attendance",
    url: "/employee_manually_attendance",
    iosIcon: handRightOutline,
    mdIcon: handRightOutline,
  },
  // {
  //   title: "user Attendance History",
  //   url: "/user_attendance_history",
  //   iosIcon: personOutline,
  //   mdIcon: personCircleOutline,
  // },
  {
    title: "Settings",
    url: "/settings",
    iosIcon: settingsOutline,
    mdIcon: settingsOutline,
  },
  {
    title: "Logout",
    url: "/Login",
    iosIcon: logOutOutline,
    mdIcon: logOutOutline,
  },
];

const employeeAppPages: AppPage[] = [
  // {
  //   title: "My account",
  //   url: "/my-account",
  //   iosIcon: personOutline,
  //   mdIcon: personCircleOutline,
  // },
  {
    title: "Leave History",
    url: "/EmployeeLeaveHistoryDetail",
    iosIcon: newspaperOutline,
    mdIcon: newspaperOutline,
  },
  // {
  //   title: "User Attendance History",
  //   url: "/user_attendance_history",
  //   iosIcon: personOutline,
  //   mdIcon: personCircleOutline,
  // },
  {
    title: "Change Password",
    url: "/change-password",
    iosIcon: calendarClearOutline,
    mdIcon: calendarClearOutline,
  },
  // {
  //   title: "Notifications",
  //   url: "/notifications",
  //   iosIcon: notificationsOutline,
  //   mdIcon: notificationsOutline,
  // },
  {
    title: "Settings",
    url: "/settings",
    iosIcon: settingsOutline,
    mdIcon: settingsOutline,
  },
  {
    title: "Logout",
    url: "/Login",
    iosIcon: logOutOutline,
    mdIcon: logOutOutline,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [hasUserImage, setHasUserImage] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const [showLoading, setShowLoading] = useState(false);
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const data: any = JSON.parse(localStorage.getItem("user_data") as string);
  const [selectedImage, setSelectedImage] = useState<any>();
  var [image, setImage] = useState<any>("");

  if (localStorage.getItem("user_data") !== null) {
    var token = JSON.parse(localStorage.getItem("user_data") as string).token
      .token;
  }

  const key = JSON.parse(localStorage.getItem("user_data") as string)
    ? JSON.parse(localStorage.getItem("user_data") as string).user.role.key
    : null;
  let menus: any = "";
  if (key && key == "company") {
    menus = companyAppPages;
  } else {
    menus = employeeAppPages;
  }

  if (localStorage.getItem("user_data") === null) {
    var idd = "";
  } else {
    idd = JSON.parse(localStorage.getItem("user_data") as string).user.id;
  }
  const handlLogout = (arg: any, id: any) => {
    if (arg.title == "Leave History") {
      const cookies = new Cookies();
      cookies.set("id", id, { path: "/" });
    } else if (arg.title == "Logout") {
      localStorage.clear();
      setShowLoading(false);
      history.replace("/login");
    } else if (arg.title == "My account") {
      const cookies = new Cookies();
      cookies.set("id", id, { path: "/" });
    } else {
      history.push(arg.url);
    }
  };

  const userSettings = () => {
    var user_details = JSON.parse(localStorage.getItem("user_data") as string);
    setUserDetails(user_details.user);
    setSpinnerLoader(true);
    if (user_details.user.image != null) {
      setSpinnerLoader(true);
      setHasUserImage(true);
      setSpinnerLoader(false);
    }
  };

 useIonViewWillEnter(() => {
    setShowLoading(true);
    if (image !== "") {
      axios
        .post(
          base_url + "/api/attendance/update-profile-image",
          {
            image: image,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("user_data") as string).token
                  .token,
            },
          }
        )
        .then(function (response) {
          if (response.data.status == true) {
            var user_data = JSON.parse(
              localStorage.getItem("user_data") as string
            );
            user_data.user.image = response.data.user.image;
            user_data.user.image_url = response.data.user.image_url;
            localStorage.setItem('user_data', JSON.stringify(user_data));
            history.push("/change_profile_picture");
            setShowLoading(false);
          } else {
            alert(response.data.message);
            history.push("/change_profile_picture");
            setShowLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [image]);

  useIonViewWillEnter(() => {
    setShowLoading(true);
    const { token } = JSON.parse(
      localStorage.getItem("user_data") as string
    ).token;
    axios
      .post(
        base_url + "/api/account-setting-get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setSelectedImage(res.data.data.image_url);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const takePicture = async () => {
    const cameraResult = await Camera.getPhoto({
      quality: 20,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos,
    })
      .then(async (cameraResult) => {
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        let base64Image = "data:image/jpeg;base64," + cameraResult.base64String;
        setImage(base64Image);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <IonMenu
      onIonWillOpen={() => userSettings()}
      menuId=""
      contentId="main"
      type="overlay"
      swipe-gesture={false}
    >
      <IonContent>
        <div className="profiledetail">
          <div className="personimg">

            <IonAvatar>
              {/* {spinnerLoader ? (
                <IonSpinner className="load-spinner" name="crescent" />
              ) : ( */}
                <IonImg
                  src={
                    hasUserImage
                      ? userDetails.image_url
                      : require("../assets/images/user.png").default
                  }
                />
                {/* <IonIcon
                  className="camera_icon"
                  icon={cameraOutline}
                  onClick={() => takePicture()}
                /> */}
              {/* )} */}
              {/* <IonImg
                src={
                  hasUserImage
                    ? userDetails.image_url
                    : require("../assets/images/user.png").default
                }
              /> */}
              
            </IonAvatar>
          </div>
          <div className="emailinfo">
            <h3>{data ? `${data.user.first_name} ${data.user.last_name}`:""}</h3>
            <IonText>
              <p>{data ? data.user.email : ""}</p>
            </IonText>
          </div>
        </div>
        <IonList id="inbox-list">
          {menus.map((appPage: any, index: any) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  onClick={() => handlLogout(appPage, idd)}
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                  <IonLoading
                    cssClass="my-custom-class"
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={"Please wait..."}
                  />
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
