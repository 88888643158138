import { useHistory } from "react-router-dom";
import { IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonIcon, IonAvatar, IonImg } from '@ionic/react';
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./Account_Update.scss"
import { arrowBackOutline, notifications } from "ionicons/icons";
import axios from "axios";
import { base_url } from "../App";
import PullToRefresh from "../components/PullToRefresh";
const Notifications: React.FC = () => {
    const { token } = JSON.parse(localStorage.getItem("user_data") as string).token
    const [notifications, setNotifications] = useState([
        {
            id: "",
            title: "",
            date_time: "",
            company_logo: "",
            description: ""
        }
    ])
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory();

    const backButtonClick = () => {
        history.push("/Home");
    }

    useEffect(() => {
        setShowLoading(true);
        axios.get(base_url + `/api/notification/show`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
            .then((res) => {
                setNotifications(res.data.data);
                setShowLoading(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    const handleData = (userData: any) => {
        if (!userData) {
          notification();
        }
      }
      const notification = () => {
        setShowLoading(true);
        axios.get(base_url + `/api/notification/show`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
            .then((res) => {
                setNotifications(res.data.data);
                setShowLoading(false);
            })
            .catch((err) => {
                console.log(err);
            })
      }
    
    return (
        <IonPage>
           
            <IonContent fullscreen>
                <PullToRefresh userDataProps={handleData}/>
                <IonHeader>
                <IonToolbar className="setting-toolbar">
                    <div className="Account-main-div">
                        <div className="back_btn">
                            <IonButtons slot="start">
                                <IonIcon icon={arrowBackOutline} onClick={backButtonClick} />
                                <h4>Notifications</h4>
                            </IonButtons>
                        </div>
                        {token ?
                            <div className="py-6 profile_after">
                                <IonAvatar className="wh-4">
                                    <IonImg src={require('../assets/images/profiletest.webp').default} />
                                </IonAvatar>
                            </div>
                            : <div className="py-6">
                                <IonAvatar className="wh-4">
                                    <IonImg src={require('../assets/images/user.png').default} />
                                </IonAvatar>
                            </div>
                        }
                    </div>
                </IonToolbar>
            </IonHeader>
                <div className="position_relative">
                    {notifications.map((item, index) => {

                        <div className="notification_detail" key={index}>
                            <div>
                                <IonAvatar className="bx_shadow">
                                    <IonImg src={require('../assets/images/sumsolslogo.png').default} />
                                </IonAvatar>
                            </div>
                            <div className="ml_11">
                                <h6 className="m_0 notification_heading">{item.title}</h6>
                                <p className="m_0 notification_explanation">{item.description}</p>
                            </div>
                            <h5 className="notification_time">{item.date_time}</h5>
                        </div>
                    })}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Notifications;