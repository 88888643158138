// import React, { useState } from 'react';
// import Clock from 'react-clock';

// function App() {
//   const [value, setValue] = useState(new Date());
//   const [alarmTime, setAlarmTime] = useState(null);

//   function handleAlarmChange(event:any) {
//     setAlarmTime(event.target.value);
//   }

//   function handleAlarmSet() {
//     // Convert the alarmTime to a Date object
//     if (alarmTime !== null) {
//       // Convert the alarmTime to a Date object
//       const alarmDate = new Date(alarmTime);
//       setValue(alarmDate);
//     }
//   }

//   function handleAlarmClear() {
//     setAlarmTime(null);
//   }

//   return (
//     <div>
//       <Clock value={value} />
//       <div>
//         <input type="time" value={alarmTime} onChange={handleAlarmChange} />
//         <button onClick={handleAlarmSet}>Set Alarm</button>
//         <button onClick={handleAlarmClear}>Clear Alarm</button>
//       </div>
//     </div>
//   );
// }

// export default App;
import React from 'react'

const AnalogClock = () => {
  return (
    <div>AnalogClock</div>
  )
}

export default AnalogClock