import { IonAvatar, IonLoading, useIonViewWillEnter } from "@ionic/react";
import axios from "axios";
import { base_url } from "../App";
import { useState, useEffect } from "react";
import PullToRefresh from "./PullToRefresh";

const AttendanceHistory = () => {
  const [loading, setLoading] = useState(false);

  interface UserHistory {
    id: number;
    user_id: number;
    location: string;
    distance: string;
    status: string;
    latitude: string;
    longitude: string;
    altitude: string | null;
    image: string;
    attendance_datetime: string;
    co_location: string | null;
    co_longitude: string | null;
    co_latitude: string | null;
    co_image: string | null;
    co_distance: string | null;
    check_out_datetime: string | null;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    formatted_datetime: string;
    image_url: string;
    distance_in_meter: number;
    attandance_time: string;
    total_attendance_current_month: number;
    total_days_in_current_month: number;
  }

  const [attendanceHistory, setAttendanceHistory] = useState<{ checkIn: UserHistory[], checkOut: UserHistory[] }>({ checkIn: [], checkOut: [] });

  const fetchAttendanceHistory = () => {
    setLoading(true);
    axios.post(
      base_url + "/api/attendance/history",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem('user_data') as string).token.token,
        },
      }
    )
      .then(async (res) => {
        let data = res.data.data;
        console.log("API Response Data:", data); // Debug statement

        if (data) {
          setAttendanceHistory(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err: err.message });
      });
  };

  useIonViewWillEnter(() => {
    fetchAttendanceHistory();
  }, []);

  useEffect(() => {
    fetchAttendanceHistory();
  }, []);

  const handleData = (userData: any) => {
    if (!userData) {
      fetchAttendanceHistory();
    }
  };

  return (
    <div className="history">
      <PullToRefresh userDataProps={handleData} />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Please wait...'}
      />
      {!loading && attendanceHistory.checkIn.length === 0 ? (
        <p>No attendance history available.</p>
      ) : (
        attendanceHistory.checkIn.map((checkIn, index) => {
          const checkOutData = attendanceHistory.checkOut.find(co => co.id === checkIn.id);
          return (
            <div key={index}>
              <div className="history_detail">
                <div className="history_status">
                  <IonAvatar className="employee_image">
                    <img src={checkIn.image_url ? checkIn.image_url : require("../assets/images/user.png").default} alt="" />
                  </IonAvatar>
                  <div className="date_location_history">
                    <h5>{checkIn.attandance_time}  {checkOutData && checkOutData.check_out_datetime && (
                      <span>To {new Date(checkOutData.check_out_datetime).toLocaleTimeString()}</span>
                    )}</h5>
                    <h4>{checkIn.formatted_datetime}</h4>
                    
                  </div>
                </div>
                <div className="attendance-status">
                  <h4>{checkIn.status}</h4>
                  <h5>{checkIn.distance_in_meter} m</h5>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default AttendanceHistory;
