import {IonButtons, IonContent, IonHeader, IonProgressBar, IonIcon, IonPage, IonToolbar} from '@ionic/react';
import {homeOutline,arrowBackOutline} from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import LeaveHistoryData from '../components/LeaveHistoryData';
import "./Report.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./Home.scss";
import LeaveHistoryHeader from '../components/LeaveHistoryHeader';
import PullToRefresh from '../components/PullToRefresh';

const LeaveHistoryDetail: React.FC = () => {
    
    return (
        
        <IonPage className="wrapper-1">
            <IonContent fullscreen>
                <PullToRefresh />
        <LeaveHistoryHeader/>    
                <LeaveHistoryData/>
            </IonContent>
        </IonPage>
    );
};

export default LeaveHistoryDetail;
